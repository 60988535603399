
import './sass/main.scss';

// Dependencies
import React, { Component } from 'react';
import Slider from 'rc-slider';

// Custom Components
import BingoBoard from './subcomponents/BingoBoard.js';
import Pattern from './subcomponents/Pattern.js';
import CallHistory from './subcomponents/CallHistory.js';

import { Stack,TextField } from '@mui/material';
import { connect } from 'react-redux';
import { resetSelectedBoardIds,addOrRemoveSelectedBoard } from 'src/redux/slices/boardSlice';
import { reset_winning_info,include_disqualified_boards_on_restart,disqualify_player} from 'src/redux/slices/boardSlice';
import { reset_gamePlay_data, set_game_is_over,reset_winner_lists,set_bet_amount,set_game_type,set_total_players,set_trx_id } from 'src/redux/slices/gamePlaySlice';
import { set_selected_pattern,set_refund_pattern } from 'src/redux/slices/transactionSlice';
import { create_winning_transaction } from 'src/redux/slices/gamePlaySlice';
import { get_credit_for_user } from 'src/redux/slices/creditSlice';
import { changeLanguage } from 'src/redux/slices/companySlice';

// Utilities
import {
  generateBingoBoard,
  getRandomBingoNumber,
  getPresetPatterns,
  getBallDisplay,
  getLogoBallDisplay,
  getLanguageText,
} from './utils.js';

// Chimes
import { chime2,readyPlay,tigPlayStart,oroPlayStart,wolPlayStart,shuffle } from './chimes';

import {SelectVoice,SelectTigregnaVoice,SelectOromiffaVoice,SelectWolaytaVoice,SelectAmharicMaleVoice,SelectAmharicWeirdVoice} from './BingoVoiceSelector';

import CartelaNumberField from './subcomponents/CartelaNumberField';
import CartelaSelect from './subcomponents/CartelaSelect';
import RefundPattern from './subcomponents/RefundPattern';
import SelectedPatternDisplay from './subcomponents/SelectedPatternDisplay';


//save failed transactions
// Utility function to save failed transactions to local storage

//this saving is handled from the service method where axios is dispatched

// const saveFailedTransactionToLocalStorage = (transaction) => {
//   const failedTransactions = JSON.parse(localStorage.getItem('_hag_ftrxs_') || '[]');
//   failedTransactions.push({ ...transaction, timestamp: new Date().toISOString() });
//   localStorage.setItem('_hag_ftrxs_', JSON.stringify(failedTransactions));
// };



//end save failed transactions



class BingoGame extends Component {
  constructor(props) {
    super(props);
    // -------------------------- Set properties ----- //
    // Balls display pieces
    this.totalBallsCalled = 0;
    this.previousBall = null;
    this.currentBall = null;
    this.interval = null;
    this.chimes = [{ label: 'Chime 2', value: chime2 }];
    this.shuffleSound = shuffle;
    this.readyPlaySound = readyPlay;
    this.tigReadyPlaySound = tigPlayStart;
    this.oroReadyPlaySound = oroPlayStart;
    this.wolReadyPlaySound = wolPlayStart;

    //failed transactions
    this.retryInterval = null;

    // Patterns
    this.patternPlaceholder = 'Choose a pattern';
    this.presets = getPresetPatterns();

    // Speech Synthesis
    this.speechEnabled = Object.prototype.hasOwnProperty.call(window, 'speechSynthesis');
    this.synth = window.speechSynthesis;

    // if speech is enabled, initialize other speech properties
    if (this.speechEnabled === true) {
      this.synth.onvoiceschanged = this.loadVoices;
      this.voices = this.synth.getVoices();
    }

    let gameData = JSON.parse(localStorage.getItem('lpb-gameData'));
    let gameState = JSON.parse(localStorage.getItem('lpb-gameState'));
    let reduxState = JSON.parse(localStorage.getItem('lpb-rstate'));

    if (gameData && gameState) {
      for (let key in gameData) {
        this[key] = gameData[key];
      }
      //this.state = gameState;
      this.state = {
        ...gameState,
        check_result_field_active: false,
        running: false,
        enableCaller: true,
        is_after_pause: false,
        isFromCacheResume: reduxState ? true : false, //this state is to enable the reset button if we are starting from memory
      };

      if (reduxState) {
        console.log('redux state update from storage ...');
        console.log(reduxState);
        this.setReduxStateFromStorage(reduxState);
      }
    } else {
      // Set initial state
      this.state = this.getInitialStateData();
    }
  }

  //save failed transaction
  /*componentDidMount() {
    console.log('BingoGame componentDidMount called');
    this.setupRetryMechanism();
  }*/

    // Updated utility function to retry saving failed transactions
retryFailedTransactions = async (saveTransaction) => {
  try {
    const failedTransactions = JSON.parse(localStorage.getItem('_hag_ftrxs_') || '[]');
    if (!failedTransactions.length) {
      console.log('No failed transactions found.');
      return;
    }

    console.log(`Retrying ${failedTransactions.length} failed transactions.`);

    const updatedFailedTransactions = [];

    for (const transaction of failedTransactions) {
      try {
        // Attempt to save the transaction
        var result = await saveTransaction(transaction);
        console.log(result);
        if(this.props.trx_saved){
          console.log(`Successfully saved transaction: ${transaction.trx_id}`);
        }else{
          console.error('Failed to save transaction:');
        // If failed again, add back to the list
          updatedFailedTransactions.push(transaction);
        }
        
      } catch (error) {
        console.error('Failed to save transaction:', error);
        // If failed again, add back to the list
        updatedFailedTransactions.push(transaction);
      }
    }

    // Update localStorage with any remaining failed transactions
    localStorage.setItem('_hag_ftrxs_', JSON.stringify(updatedFailedTransactions));
  } catch (error) {
    console.error('Error processing failed transactions:', error);
  }
};

  componentDidUpdate(prevProps, prevState) {
    //console.log('BingoGame componentDidUpdate called');
  }

  componentWillUnmount() {
    console.log('BingoGame componentWillUnmount called');
    this.cleanupRetryMechanism();
  }

  setupRetryMechanism = () => {
    console.log('Setting up retry mechanism');
    // Set up interval for retrying every 2 minutes
    this.retryInterval = setInterval(this._retryFailedTransactions, 10 * 60 * 1000);
    
    // Set up beforeunload event listener
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    
    // Immediate retry attempt on component mount
    this._retryFailedTransactions();
  }

  cleanupRetryMechanism = () => {
    console.log('Cleaning up retry mechanism');
    if (this.retryInterval) {
      clearInterval(this.retryInterval);
    }
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  _retryFailedTransactions = async () => {
    console.log('Attempting to retry failed transactions');
    try {
      await this.retryFailedTransactions(this.props.create_winning_transaction);
      console.log('Retry attempt completed');
    } catch (error) {
      console.error('Error during retry of failed transactions:', error);
    }
  }

  handleBeforeUnload = (event) => {
    console.log('Before unload event triggered');
    try {
      this._retryFailedTransactions();
    } catch (error) {
      console.error('Error during beforeunload retry:', error);
    }
    event.returnValue = ''; // Necessary for some browsers to trigger the unload event
  }
  //end save failed transaction

  setReduxStateFromStorage(reduxState) {
    try {
      const selectedBoardIds = reduxState._selected_board_ids;
      const betAmount = reduxState._save_bet_amount;
      const gametype = reduxState._save_game_type;
      const totalWinnings = reduxState._total_winnings;
      const totalPlayers = reduxState._total_players;
      const gameIsOver = reduxState._game_is_over;
      const disqualifiedPlayers = reduxState._disqualified_board_ids;
      const trxId = reduxState._trx_id;
      const selectedVoice = reduxState._running_voice;

      if (selectedVoice) {
        this.props.changeLanguage(selectedVoice);
      }

      if (selectedBoardIds) {
        selectedBoardIds.map((value) => {
          this.props.addOrRemoveSelectedBoard(value);
        });
      }

      if (totalWinnings) {
      }

      if (trxId) {
        //add this method on gameplayslice
        this.props.set_trx_id(trxId);
      }

      if (betAmount) {
        this.props.set_bet_amount(betAmount);
      }

      if (gametype) {
        this.props.set_game_type(gametype);
      }

      if (totalPlayers) {

        const param = {
          _total_players: totalPlayers,
          _saved_game_type: gametype,
          _play_percent: 5,//default because i am bored
        };

        this.props.set_total_players(param);
      }

      if (gameIsOver) {
        this.props.set_game_is_over(gameIsOver);
      }

      if (disqualifiedPlayers) {
        disqualifiedPlayers.map((value) => {
          this.props.disqualify_player(value);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  getInitialStateData() {
    return {
      board: generateBingoBoard(),
      isFromCacheResume: false,
      previousCallList: [],
      playing_sound: null,
      is_after_pause: false,
      displayBoardOnly: false,
      delay: 4000,
      running: false,
      enableCaller: false,
      skipUnused: true,
      wildBingo: false,
      evensOdds: false,
      doubleCall: false,
      extraTalk: true,
      chime: true,
      selectedChime: this.chimes[0],
      selectedCaller: null,
      manualTrxSave: false,
      usePattern: false,
      displayPattern: false,
      showGameType:true,
      useRefundPattern: false,
      isRestart: false,
      check_result_field_active: false,
      bet_amount: 0,
      game_type: 4,
      total_winnings: 0,
      selected_cartela_ids: [],
      enable_cartela_selection: true,
      start_game_play_button_disabled: false,
      manual_call_button_disabled: false,
      selectedPattern: {
        value: this.patternPlaceholder,
        label: this.patternPlaceholder,
        pattern: {
          B: [false, false, false, false, false],
          I: [false, false, false, false, false],
          N: [false, false, false, false, false],
          G: [false, false, false, false, false],
          O: [false, false, false, false, false],
        },
      },
      showResetModal: false,
    };
  }

  /**
   * In case of going from one page to another, when we return
   * and the component has mounted reinitialize the game from
   * local storage.
   *
   */
  componentDidMount() {
    this.loadVoices();
    // ensure the reset modal doesn't show at initial load
    this.setState({ showResetModal: false });
    console.log('BingoGame componentDidMount called');
    this.setupRetryMechanism();
  }

  /**
   * [componentDidUpdate description]
   *
   * @param   {[type]}  prevProps  [prevProps description]
   * @param   {[type]}  state      [state description]
   *
   * @return  {[type]}             [return description]
   * this method is called every single time any state of the component is updated
   */
  componentDidUpdate(prevProps, state) {
    let gameData = {
      totalBallsCalled: this.totalBallsCalled,
      previousBall: this.previousBall,
      currentBall: this.currentBall,
      interval: this.interval,
    };
    localStorage.setItem('lpb-gameData', JSON.stringify(gameData));
    localStorage.setItem('lpb-gameState', JSON.stringify(this.state));
  }

  /**
   * [initializeFromLocalStorage description]
   *
   * @return  {[type]}  [return description]
   */
  initializeFromLocalStorage = () => {
    let gameData = JSON.parse(localStorage.getItem('lpb-gameData'));
    let gameState = JSON.parse(localStorage.getItem('lpb-gameState'));
    if (gameData && gameState) {
      for (let key in gameData) {
        this[key] = gameData[key];
      }
      this.setState(...gameState);
    }
  };

  /* ------------------- Speech Synthesis Functions */
  /*
   *  Load Voices Function
   *  Will load voices as they change within the browser
   */
  loadVoices = () => {
    this.voices = this.synth.getVoices();
    let selectedCaller = this.state.selectedCaller;
    if (selectedCaller === null) {
      // if the selected caller is STILL null, set to the first voice available.
      // this is a one off that really would only happen if the user's browser
      // has a language that doesn't have a caller available for it.
      selectedCaller = this.voices[0];
    }
    let userLanguage = window.navigator.userLanguage || window.navigator.language;
    // loop through voices and either choose the one that matches the selection or choose the first one that matches user's language
    this.voices.forEach((voice) => {
      if (
        selectedCaller !== null &&
        selectedCaller !== undefined &&
        Object.prototype.hasOwnProperty.call(selectedCaller, 'value')
      ) {
        if (voice.name === selectedCaller.value) {
          this.setState({ selectedCaller: voice });
        }
      } else {
        if (voice.lang === userLanguage) {
          selectedCaller = voice;
        }
      }
    });
    this.setState({ selectedCaller: selectedCaller });
  };

  /*
   *  Say Function
   *  Will speak any string that is passed in
   */
  say = (text) => {
    if (this.speechEnabled === true && this.state.enableCaller === true) {
      // Create a new instance of SpeechSynthesisUtterance.
      let msg = new SpeechSynthesisUtterance();
      msg.text = text;
      msg.volume = 1;
      if (Object.prototype.hasOwnProperty.call(this.state, 'selectedCaller')) {
        this.voices.forEach((caller) => {
          if (caller.value === this.state.selectedCaller.value) {
            msg.voice = caller;
          }
        });
      }
      this.cancelSpeech();
      this.synth.speak(msg);
    }
  };

  /**
   * Cancel speech function
   * Will cancel any existing speech
   */
  cancelSpeech = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }
  };

  /**
   * Handles the audible call of the ball
   *
   * @param   {Object}  ball  Object representing a ball
   */
  voiceCall = (ball) => {
    // call the new ball, first call it all together, then call each character individually
    let ballstring = ball.number.toString();
    if (this.state.doubleCall) {
      this.say([
        ball.letter,
        ball.number,
        ' ',
        ' ',
        ball.letter,
        ' ',
        ballstring.length === 2 ? [ballstring.charAt(0), ' ', ballstring.charAt(1)] : ball.number,
      ]);
    } else {
      if (this.props.bingo_play_language == 1) {
        //play tigrigna language
        let tigrigna = SelectTigregnaVoice(ball.letter, ball.number);
        // let audio = new Audio(tigrigna);
        // audio.play();
        this.state.playing_sound = new Audio(tigrigna);
        this.state.playing_sound?.play();
        //audio.play();
      } else if (this.props.bingo_play_language == 2) {
        let oromifa = SelectOromiffaVoice(ball.letter, ball.number);
        this.state.playing_sound = new Audio(oromifa);
        this.state.playing_sound?.play();
      } else if (this.props.bingo_play_language == 3) {
        let wolayta = SelectWolaytaVoice(ball.letter, ball.number);
        this.state.playing_sound = new Audio(wolayta);
        this.state.playing_sound?.play();
      } else if (this.props.bingo_play_language == 4) {
        let amharic_male = SelectAmharicMaleVoice(ball.letter, ball.number);
        this.state.playing_sound = new Audio(amharic_male);
        this.state.playing_sound?.play();
      } 
      else if (this.props.bingo_play_language == 5) {
        let amharic_male = SelectAmharicWeirdVoice(ball.letter, ball.number);
        this.state.playing_sound = new Audio(amharic_male);
        this.state.playing_sound?.play();
      } 
      else {
        //default: play amharic female
        let amharic = SelectVoice(ball.letter, ball.number);
        this.state.playing_sound = new Audio(amharic);
        this.state.playing_sound?.play();
      }

      //this.say([ball.letter, ball.number]);
    }
  };

  /**
   * Handles a wild ball call when the wild bingo game mode is active
   *
   * @param   {Object}  ball  Object representing a ball
   */
  wildBallCall = (ball) => {
    // call the wild ball,
    let ballstring = ball.number.toString();
    if (this.state.extraTalk) {
      if (this.state.evensOdds) {
        window.setTimeout(() => {
          this.say([
            'The wild number ',
            ' ',
            ball.letter,
            ' ',
            ball.number,
            ' ',
            ' ',
            ` mark every ${ball.number % 2 === 1 ? 'odd number' : 'even number'}`,
          ]);
        }, 2000);
      } else {
        window.setTimeout(() => {
          this.say([
            'The wild number ',
            ' ',
            ball.letter,
            ' ',
            ball.number,
            ' ',
            ' ',
            ` mark every number ending in ${ballstring.substr(-1)}`,
          ]);
        }, 2000);
      }
    } else {
      if (this.state.doubleCall) {
        this.say([
          ball.letter,
          ball.number,
          ' ',
          ' ',
          ball.letter,
          ' ',
          ballstring.length === 2 ? [ballstring.charAt(0), ' ', ballstring.charAt(1)] : ball.number,
        ]);
      } else {
        this.say([ball.letter, ' ', ball.number]);
      }
    }
  };

  /* ------------------- Gameplay Functions */

  startNewGame = () => {
    // if (this.state.bet_amount <= 0) {
    //   alert('bet amount is zero ' + this.state.bet_amount);
    //   return;
    // }

    if (this.props.game_is_over) {
      alert('game is over. please restart or reset game!');
      return;
    }

    if (
      this.props.logged_in_user.is_fixed_winning &&
      (this.props.fixed_winning == null || this.props.fixed_winning <= 0)
    ) {
      alert('total winning is not set!');
      return;
    }

    if (this.props.selected_board_ids?.length <= 0) {
      alert('Please Select playing boards first!');
      return;
    }
    // Obtain all randomized balls
    let byteArray = new Uint8Array(1);
    let randomVals = [];

    try {
      this.props.reset_winner_lists();
    } catch (error) {}

    while (randomVals.length < 75) {
      let randomVal = window.crypto.getRandomValues(byteArray)[0];
      if (randomVal > 0 && randomVal <= 75 && !randomVals.includes(randomVal)) {
        randomVals.push(randomVal);
      }
    }

    // Start with the Let's Play Bingo call out
    // (the .say method will not run if caller is not enabled)
    if (this.state.wildBingo) {
      if (this.state.enableCaller && this.state.extraTalk) {
        this.say("Let's Play Wild Bingo!");
        window.setTimeout(() => {
          this.startWildBingo();
        }, 2000);
      } else {
        this.startWildBingo();
      }
    } else {
      if (this.state.enableCaller) {
        if (this.state.extraTalk) {
          this.say("Let's Play Bingo!");
          window.setTimeout(() => {
            this.callBingoNumber();
          }, 2000);
        } else {
          this.callBingoNumber();
        }
      } else {
        this.callBingoNumber();
      }
    }
  };

  getLocation = () => {
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const lon = position.coords.longitude;
            resolve({ lat, lon });
          },
          (error) => {
            reject(error);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  /*startNewAutoplayGame = async () => {
    if (
      this.props.logged_in_user.role !== 'Employee' &&
      this.props.logged_in_user.role !== 'super' &&
      this.props.logged_in_user.role !== 'root'
    ) {
      alert('only cashiers have previlage to start new games! \n (ከ ካሺየር በስተቀር ማጫወት አይቻልም!)');
      return;
    }

    if (this.props.game_is_over) {
      alert('game is over. please restart or reset game!');
      return;
    }

    this.setState({ manual_call_button_disabled: true });

    try {
      if (
        this.props.logged_in_user.is_fixed_winning &&
        (this.props.fixed_winning == null || this.props.fixed_winning <= 0)
      ) {
        alert('total winning is not set!');
        this.setState({ manual_call_button_disabled: false });
        return;
      }
      if (!this.props.logged_in_user.is_fixed_winning && !this.props.total_winnings && this.props.total_winnings <= 0) {
        alert('bet amount/game type or playing boards not set!');
        this.setState({ manual_call_button_disabled: false });
        return;
      }
      if (this.props.selected_board_ids?.length <= 0) {
        alert('select playing boards first');
        this.setState({ manual_call_button_disabled: false });
        return;
      }

      if (!this.props.save_bet_amount || this.props.save_bet_amount <= 0) {
        alert('please add bet amount');
        this.setState({ manual_call_button_disabled: false });
        return;
      }

      try {
        await this.props.get_credit_for_user(this.props.logged_in_user?.phone);
        let current_balance = this.props._credit;
        let bet_amount = this.props.save_bet_amount;
        let board_count = this.props.selected_board_ids?.length;
        let total_winnings = this.props.total_winnings;
        if (current_balance && current_balance.remaining_credit < board_count * bet_amount - total_winnings) {
          alert(`Insufficent Balance of ${current_balance.remaining_credit} birr`);
          this.setState({ manual_call_button_disabled: false });
          return;
        }
      } catch (error) {
        console.log(error);
        console.log('error thrown during balance check!');
      }

      this.setState({ enable_cartela_selection: false });

      //reset winner list
      try {
        this.props.reset_winner_lists();
      } catch (error) {}
      //location
      let _lat = 0;
      let _lon = 0;

      try {
        const { lat, lon } = await this.getLocation();
        _lat = lat;
        _lon = lon;
        //alert(`lat is ${_lat},long is ${_lon}`);
      } catch (error) {
        console.error('Error getting location:', error);
      }
      //save trx first
      var game_data_to_save = null;
      try {
        //if trx saving is failed game play should not be interrupted
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 10000);

        let trx_id = `${timestamp}-${random}`;

        game_data_to_save = {
          trx_id: trx_id,
          game_type: this.props.save_game_type,
          bet_amount: this.props.save_bet_amount,
          winning_board_id: this.props.save_winning_board_id,
          fixed_amount: this.props.fixed_winning,
          selected_boards: this.props.selected_board_ids,
          call_list: this.state.previousCallList,
          lat: _lat,
          lon: _lon,
        };
        this.props.create_winning_transaction(game_data_to_save);
      } catch (error) {
        alert('unable to save transaction');
        console.log(error);
      }
      //here save to local storage
      try {
        this.saveReduxStateToLocalStorage();
      } catch (error) {
        console.log(error);
      }

      //save redux state data to localstorage here
      this.toggleGame();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };*/

  startNewAutoplayGame = async () => {
    if (this.props.logged_in_user.role !== "Employee" && this.props.logged_in_user.role !== "super" && this.props.logged_in_user.role !== "root") {
      alert('only cashiers have privilege to start new games! \n (ከ ካሺየር በስተቀር ማጫወት አይቻልም!)');
      return;
    }

    if (this.props.game_is_over) {
      alert('game is over. please restart or reset game!');
      return;
    }

    this.setState({ manual_call_button_disabled: true });

    try {
      if (
        this.props.logged_in_user.is_fixed_winning &&
        (this.props.fixed_winning == null || this.props.fixed_winning <= 0)
      ) {
        alert('total winning is not set!');
        this.setState({ manual_call_button_disabled: false });
        return;
      }
      if (!this.props.logged_in_user.is_fixed_winning && !this.props.total_winnings && this.props.total_winnings <= 0) {
        alert('bet amount/game type or playing boards not set!');
        this.setState({ manual_call_button_disabled: false });
        return;
      }
      if (this.props.selected_board_ids?.length <= 0) {
        alert('select playing boards first');
        this.setState({ manual_call_button_disabled: false });
        return;
      }

      if (!this.props.save_bet_amount || this.props.save_bet_amount <= 0) {
        alert('please add bet amount');
        this.setState({ manual_call_button_disabled: false });
        return;
      }

      try {
        await this.props.get_credit_for_user(this.props.logged_in_user?.phone);
        let current_balance = this.props._credit;
        let bet_amount = this.props.save_bet_amount;
        let board_count = this.props.selected_board_ids?.length;
        let total_winnings = this.props.total_winnings;
        if (current_balance && current_balance.remaining_credit < (board_count * bet_amount - total_winnings)) {
          alert(`Insufficient Balance of ${current_balance.remaining_credit} birr`);
          this.setState({ manual_call_button_disabled: false });
          return;
        }
      } catch (error) {
        console.log(error);
        console.log('error thrown during balance check!');
      }

      this.setState({ enable_cartela_selection: false });

      try {
        this.props.reset_winner_lists();
      } catch (error) {
        console.error("Error resetting winner lists:", error);
      }

      let _lat = 0;
      let _lon = 0;

      try {
        const { lat, lon } = await this.getLocation();
        _lat = lat;
        _lon = lon;
      } catch (error) {
        console.error("Error getting location:", error);
      }

      const timestamp = new Date().getTime();
      const random = Math.floor(Math.random() * 10000);
      const trx_id = `${timestamp}-${random}`;

      const game_data_to_save = {
        trx_id,
        game_type: this.props.save_game_type,
        bet_amount: this.props.save_bet_amount,
        winning_board_id: this.props.save_winning_board_id,
        fixed_amount: this.props.fixed_winning,
        selected_boards: this.props.selected_board_ids,
        call_list: this.state.previousCallList,
        lat: _lat,
        lon: _lon,
        is_failed_save: false,
        use_assigned_game_type:this.state.showGameType,
      };

      

      this.saveReduxStateToLocalStorage();
      
      this.toggleGame();

      try {
        await this.props.create_winning_transaction(game_data_to_save);
      } catch (error) {
        console.error('Failed to save transaction:', error);
        // game_data_to_save.is_failed_save = true;
        // saveFailedTransactionToLocalStorage(game_data_to_save);
      }

    } catch (error) {
      console.error("Error in startNewAutoplayGame:", error);
    } finally {
      //this.setState({ manual_call_button_disabled: false });
    }
  };

  saveReduxStateToLocalStorage() {
    try {
      let reduxStates = {
        _selected_board_ids: this.props.selected_board_ids,
        _disqualified_board_ids: this.props.disqualified_board_ids,
        _total_winnings: this.props.total_winnings,
        _fixed_winning: this.props.fixed_winning,
        _total_players: this.props.selected_board_ids?.length,
        _game_is_over: this.props.game_is_over,
        _save_game_type: this.props.save_game_type,
        _save_bet_amount: this.props.save_bet_amount,
        _trx_id: this.props.current_trx_id,
        _running_voice: this.props.bingo_play_language,
      };

      localStorage.setItem('lpb-rstate', JSON.stringify(reduxStates));
    } catch (error) {
      console.log(`local storage error -> ${error}`);
    }
  }

  debounce = (func, delay) => {
    let timeoutId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
  };

  //startNewAutoplayGameDebounced = this.debounce(this.startNewAutoplayGame, 500);

  startWildBingo = () => {
    // Variables used for wild bingo
    let randomBingoNumber = getRandomBingoNumber();
    let wildNumber = randomBingoNumber.toString().slice(-1);
    let odd = wildNumber % 2 === 1;
    let wildBall = null;
    let lastBall = null;
    let board = this.state.board;
    let totalBallsCalled = this.totalBallsCalled;
    let previousCallList = this.state.previousCallList.length > 0 ? [...this.state.previousCallList] : [];

    Object.keys(board).forEach((letter) => {
      board[letter].forEach((number) => {
        if (!number.called) {
          if (number.number === randomBingoNumber) {
            this.setState({ wildBall: letter + ' ' + randomBingoNumber });
            number.called = true;
            number.active = true;
            wildBall = number;
            if (this.state.enableCaller) {
              this.wildBallCall(number);
            }
            totalBallsCalled++;
            previousCallList.push(number);
          } else if (!this.state.evensOdds && number.number.toString().slice(-1) === wildNumber) {
            lastBall = number;
            number.called = true;
            totalBallsCalled++;
            previousCallList.push(number);
          } else if (this.state.evensOdds && (number.number % 2 === 1) === odd) {
            lastBall = number;
            number.called = true;
            totalBallsCalled++;
            previousCallList.push(number);
          }
        }
        return number;
      });
      return letter;
    });

    this.totalBallsCalled = totalBallsCalled;
    this.previousBall = lastBall;
    this.currentBall = wildBall;
    this.setState({ board: board, previousCallList: [...previousCallList] });
  };

  toggleGame = () => {
    if (this.props.game_is_over) {
      alert('game is over. please restart or reset game!');
      return;
    }

    //if there are any running voices stop them.
    //come here

    try {
      if (this.state.playing_sound /*&& !this.state.isFromCacheResume*/) {
        this.state.playing_sound?.pause();
        this.state.playing_sound.currentTime = 0;
        this.state.is_after_pause = true;
        //console.log('trying pausing game');
      } else {
        //console.log('not condition -> ' + this.state.isFromCacheResume)
      }
    } catch (error) {
      //console.log('is from cache resume -> ' + this.state.isFromCacheResume)
      //console.log(error);
    }

    //reset winner list
    try {
      this.props.reset_winner_lists();
    } catch (error) {}

    this.setState({ check_result_field_active: !this.state.check_result_field_active });
    //this.setState({manual_call_button_disabled:false});
    let running = this.state.running;
    if (running === true) {
      clearInterval(this.interval);
    } else {
      this.callBingoNumber();
      this.interval = setInterval(this.callBingoNumber, this.state.delay);
    }
    this.setState({ running: !running });
  };

  toggleResetModal = () => {
    const currentState = this.state.showResetModal;
    this.setState({ showResetModal: !currentState, enable_cartela_selection: true });
  };

  confirmResetGame = () => {
    // Clear out local storage
    localStorage.removeItem('lpb-gameData');
    localStorage.removeItem('lpb-gameState');
    localStorage.removeItem('lpb-rstate');
    // reset everything with the board
    clearInterval(this.interval);
    this.cancelSpeech();
    this.totalBallsCalled = 0;
    this.previousBall = null;
    this.currentBall = null;

    this.setState({
      board: generateBingoBoard(),
      wildBall: null,
      running: false,
      showResetModal: false,
      previousCallList: [],
      manual_call_button_disabled: false,
      isFromCacheResume: false,
      playing_sound: null,
      is_after_pause: false,
      displayBoardOnly: false,
      delay: 3000,
      enableCaller: false,
      skipUnused: true,
      wildBingo: false,
      evensOdds: false,
      doubleCall: false,
      extraTalk: true,
      chime: true,
      selectedChime: this.chimes[0],
      selectedCaller: null,
      manualTrxSave: false,
      usePattern: false,
      displayPattern: false,
      useRefundPattern: false,
      isRestart: false,
      check_result_field_active: false,
      bet_amount: 0,
      game_type: 4,
      total_winnings: 0,
      selected_cartela_ids: [],
      enable_cartela_selection: true,
      start_game_play_button_disabled: false,
      manual_call_button_disabled: false,
      selectedPattern: {
        value: this.patternPlaceholder,
        label: this.patternPlaceholder,
        pattern: {
          B: [false, false, false, false, false],
          I: [false, false, false, false, false],
          N: [false, false, false, false, false],
          G: [false, false, false, false, false],
          O: [false, false, false, false, false],
        },
      },
      showResetModal: false,
    });

    //this.state = this.getInitialStateData();
    //reset winning info
    this.props.reset_winning_info();
    this.props.reset_gamePlay_data();
  };
  restartGame = () => {
    localStorage.removeItem('lpb-gameData');
    localStorage.removeItem('lpb-gameState');
    localStorage.removeItem('lpb-rstate');
    // reset everything with the board
    this.props.set_game_is_over(false);
    clearInterval(this.interval);
    this.cancelSpeech();
    this.totalBallsCalled = 0;
    this.previousBall = null;
    this.currentBall = null;

    this.setState({
      board: generateBingoBoard(),
      wildBall: null,
      running: false,
      showResetModal: false,
      previousCallList: [],
      enable_cartela_selection: true,
      manual_call_button_disabled: false,
    });
    try {
      this.props.include_disqualified_boards_on_restart(this.props.disqualified_board_ids);
    } catch (error) {
      console.log(error);
    }
  };

  callBingoNumber = () => {
    //always replay the voice right before pause; so that the middle cut audio will be re-called.

    if (this.props.game_is_over) {
      alert('game is over. please restart or reset game!');
      return;
    }

    try {
      if (this.state.is_after_pause) {
        console.log('voice is being stopped!');
        this.state.playing_sound?.play();
        this.state.is_after_pause = false;
        return;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      this.props.reset_winner_lists();
    } catch (error) {}

    let totalBallsCalled = this.totalBallsCalled;
    let selectedPattern = this.state.selectedPattern;
    let totalPossibleBalls = 75;
    if (this.state.skipUnused === true && selectedPattern.value !== this.patternPlaceholder) {
      totalPossibleBalls = 75 - selectedPattern.unusedLetters.length * 15;
    }
    if (totalBallsCalled < totalPossibleBalls) {
      let board = this.state.board;
      let currentBall = null;
      let previousBall = this.currentBall;
      let randomBingoNumber = getRandomBingoNumber();
      let callAgain = false;
      let updateState = false;
      let previousCallList = [...this.state.previousCallList];

      // Map through the letters on the board
      Object.keys(board).map((letter) => {
        // Map through each number 1-15 under each letter on the board
        board[letter].map((number) => {
          // automatically set the number as not active (this will clear any previously active numbers)
          number.active = false;
          // If this is the match to the random number we called, do logic
          if (number.number === randomBingoNumber) {
            // if the number was not called, do logic. Else call again
            if (!number.called) {
              // set to called and add to previously called numbers
              number.called = true;
              previousCallList.push(number);

              currentBall = number;
              // if we are skipping unused numbers, a pattern has been selected, and this letter is not in use, we want to call a new number when
              // we are done here.
              if (
                this.state.skipUnused &&
                selectedPattern.value !== this.patternPlaceholder &&
                selectedPattern.unusedLetters.indexOf(letter) >= 0
              ) {
                callAgain = true;
              } else {
                // increment the total balls called.
                totalBallsCalled++;
                // console.log(
                //   'total balls called ****************************** ' + totalBallsCalled + '-' + this.totalBallsCalled
                // );
                // set ball to active since we won't be calling again
                number.active = true;

                //If chime is enabled, play the chime
                if (this.state.chime) {
                  let chime = new Audio(this.chimes[0].value);
                  chime.play();
                }
                // if caller is enabled AND chimes are enabled, wait a sec to trigger the voice
                // else just call the voice right away
                if (this.state.chime && this.state.enableCaller) {
                  setTimeout(() => {
                    this.voiceCall(number);
                  }, 1000);
                } else {
                  this.voiceCall(number);
                }
              }
              updateState = true;
              this.totalBallsCalled = totalBallsCalled;
              if (this.totalBallsCalled >= 74) this.setState({ check_result_field_active: false });
            } else {
              // call again cause we got a ball we already called
              callAgain = true;
            }
          }
          return number;
        });
        return letter;
      });

      if (updateState) {
        this.previousBall = previousBall;
        this.currentBall = currentBall;
        this.setState({ board: board, previousCallList: previousCallList });
      }
      if (callAgain && totalBallsCalled < 75) {
        this.callBingoNumber();
      }
    } else {
      clearInterval(this.interval);
      this.totalBallsCalled = totalPossibleBalls;
      this.say('Someone better have a bingo because we have run out of balls to call!');
      this.previousBall = this.currentBall;
      this.currentBall = null;
      this.setState({ running: false });
    }
  };

  shuffleBalls = () => {
    let balls = generateBingoBoard();
    let letters = ['B', 'I', 'N', 'G', 'O'];
    let sound = new Audio(this.shuffleSound);
    let duration = 2000;
    for (let i = 0; i <= duration; i++) {
      window.setTimeout(() => {
        if (i === 0) {
          sound.play();
        }
        if (i > 0 && i <= duration) {
          flashRandomBall();
          this.setState({ board: balls });
        }
        if (i === duration) {
          sound.pause();
          this.confirmResetGame(false);
        }
      }, duration);
    }

    function flashRandomBall() {
      let randomLetter = letters[Math.floor(Math.random() * 5)];
      let randomNumber = Math.floor(Math.random() * 15);
      Object.keys(balls).forEach((letter) => {
        Object.values(balls[letter]).forEach((ball) => {
          if (ball.letter === randomLetter) {
            balls[randomLetter][randomNumber].active = !balls[randomLetter][randomNumber].active;
            balls[randomLetter][randomNumber].called = !balls[randomLetter][randomNumber].called;
          }
          return ball;
        });
      });
    }
  };

  readyPlayButtonClicked = () => {
    console.log(this.props.bingo_play_language);

    if (this.props.bingo_play_language == 1) {
      let sound = new Audio(this.tigReadyPlaySound);
      sound.play();
    } else if (this.props.bingo_play_language == 2) {
      let sound = new Audio(this.oroReadyPlaySound);
      sound.play();
    } else if (this.props.bingo_play_language == 3) {
      //wolReadyPlaySound
      let sound = new Audio(this.wolReadyPlaySound);
      sound.play();
    } else {
      let sound = new Audio(this.readyPlaySound);
      sound.play();
    }
  };

  /* ------------------ Handlers */
  handleDelayChange = (e) => {
    if (this.state.running === true) {
      clearInterval(this.interval);
      this.interval = setInterval(this.callBingoNumber, e);
    }
    this.setState({ delay: e });
  };

  handleCheckbox = (e) => {
    let gamemode = e.currentTarget.dataset.gamemode;
    switch (gamemode) {
      case 'skip-unused':
        this.setState({ skipUnused: e.currentTarget.checked });
        break;
      case 'enable-doublecall':
        this.setState({ doubleCall: e.currentTarget.checked });
        break;
      case 'enable-extratalk':
        this.setState({ extraTalk: e.currentTarget.checked });
        break;
      case 'wild-bingo':
        this.setState({ wildBingo: e.currentTarget.checked });
        break;
      case 'evens-odds':
        this.setState({ evensOdds: e.currentTarget.checked });
        break;
      case 'enable-caller':
        if (this.synth.speaking) {
          this.cancelSpeech();
        }
        this.setState({ enableCaller: e.currentTarget.checked });
        break;
      case 'display-board':
        if (e.currentTarget.checked && this.state.running) {
          clearInterval(this.interval);
        }
        this.setState({ displayBoardOnly: e.currentTarget.checked, running: false });
        break;
      case 'enable-chime':
        this.setState({ chime: e.currentTarget.checked });
        break;
      case 'manual-trx-save':
        this.setState({ manualTrxSave: e.currentTarget.checked });
        break;
      case 'pattern-selector':
        this.setState({ usePattern: e.currentTarget.checked });
        this.props.set_selected_pattern(null);
        break;
      case 'display-pattern':
        this.setState({ displayPattern: e.currentTarget.checked });
        break;
      case 'refund_pattern_selector':
        this.setState({ useRefundPattern: e.currentTarget.checked });
        this.props.set_refund_pattern(null);
        break;
      case 'show-game-type':
        this.setState({ showGameType: e.currentTarget.checked });
        
        let play_percent = this.props.save_game_type;
        
        //console.log(play_percent)
        if (e.currentTarget.checked) {
          //console.log(this.props.play_percentage)
          play_percent = this.props.play_percentage; //shoul be read from the server or something
          //alert('play percent is ' + play_percent);
        }
        //this.props.set_game_type(15);
        const param = {
          _total_players: this.props.selected_board_ids?.length,
          _saved_game_type: e.currentTarget.checked,
          _play_percent: parseInt(play_percent),
        };
        //console.log(param);
        this.props.set_total_players(param);

        break;
      default:
        break;
    }
  };

  handleUpdatePattern = (pattern, letter, index, slot) => {
    pattern[letter][index] = !slot;
    let unusedLetters = [];
    Object.keys(pattern).map((letter) => {
      // Check for free space ONLY first. If it's not the letter N, check for any used spaces.
      if (letter === 'N') {
        let markedSpaces = [];
        // loop through each space in the pattern for the letter N
        pattern[letter].forEach((space, index) => {
          // if the space is marked, push the index of the space into markedSpaces array
          if (space) {
            markedSpaces.push(index);
          }
        });
        // if no spaces are marked, OR ONLY the free space is marked - push N to unused letters.
        if (markedSpaces.length === 0 || (markedSpaces.length === 1 && markedSpaces[0] === 2)) {
          unusedLetters.push(letter);
        }
      } else {
        if (pattern[letter].indexOf(true) < 0) {
          unusedLetters.push(letter);
        }
      }
      return letter;
    });
    let customPattern = { value: 'Custom', label: 'Custom', unusedLetters: unusedLetters, pattern: pattern };
    this.setState({ selectedPattern: customPattern });
    console.log(customPattern);
  };

  /* ------------------- JSX Display Functions */

  /**
   * Returns a JSX element to display the current ball
   *
   * @return  {JSX}  JSX Element
   */
  get currentBallDisplay() {
    return this.currentBall !== null ? getBallDisplay(this.currentBall) : getLogoBallDisplay();
  }

  /**
   * Get Number Display shown above the pattern display
   *
   * @return  {JSX}  html element
   */
  get numberDisplay() {
    let numbers = this.totalBallsCalled.toString().split('');
    if (numbers.length === 1) {
      return (
        <div>
          <span>&nbsp;</span>
          <span>{numbers[0]}</span>
        </div>
      );
    } else {
      return numbers.map((number, index) => <span key={'numDisplay' + number + index}>{number}</span>);
    }
  }

  /**
   * Get the current call display
   *
   * @return  {JSX}  html element
   */
  get currentCallDisplay() {
    const currentCall = this.currentBall;
    if (currentCall) {
      let numbers = ['0'];
      if (Object.prototype.hasOwnProperty.call(currentCall, 'number')) {
        numbers = currentCall.number.toString().split('');
      }
      if (numbers.length === 1) {
        return (
          <div>
            <span>&nbsp;</span>
            <span>{numbers[0]}</span>
          </div>
        );
      } else {
        return numbers.map((number, index) => <span key={'call' + number + index}>{number}</span>);
      }
    } else {
      return (
        <div>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </div>
      );
    }
  }

  /**
   * Get the previous call display
   *
   * @return  {JSX}  html element
   */
  get previousCallDisplay() {
    const previousCall = this.previousBall;
    if (previousCall) {
      let numbers = ['0'];
      if (Object.prototype.hasOwnProperty.call(previousCall, 'number')) {
        numbers = previousCall.number.toString().split('');
      }
      if (numbers.length === 1) {
        return (
          <div>
            <span>&nbsp;</span>
            <span>{numbers[0]}</span>
          </div>
        );
      } else {
        return numbers.map((number, index) => <span key={'call' + number + index}>{number}</span>);
      }
    } else {
      return (
        <div>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </div>
      );
    }
  }

  /**
   * Reset confirmation modal display
   *
   * @return  {[JSX]}  Return modal or empty div
   */
  get resetConfirmationModalDisplay() {
    if (this.state.showResetModal === true) {
      return (
        <div>
          <div className="modal">
            <h4>Reset Game</h4>
            <p>Are you sure you want to reset the game?</p>
            <p className="red-text">
              This action <strong>cannot</strong> be undone.
            </p>
            <p>
              <button onClick={this.toggleResetModal}>Cancel</button>
              <button className="primaryBtn" onClick={this.confirmResetGame}>
                Confirm
              </button>
            </p>
          </div>
          <div
            className="modal-backdrop"
            onClick={(e) => {
              e.preventDefault();
            }}
          ></div>
        </div>
      );
    } else {
      return null;
    }
  }

  /* ------------------- Speech Synthesis */

  /**
   * Returns the options for the voice selection menu
   *
   * @return  {Array}  Options array
   */
  get voiceOptions() {
    let voiceOptions = [];
    if (this.speechEnabled === true) {
      this.voices.forEach((voice) => {
        let voiceObj = voice;
        voiceObj.value = voice.name;
        voiceObj.label = voice.name + ' / ' + getLanguageText(voice.lang);
        voiceOptions.push(voiceObj);
      });
    }
    return voiceOptions;
  }

  /*
   *  Choose Caller Function
   *  This sets the selected caller
   */
  handleChooseCaller = (e) => {
    this.setState({ selectedCaller: e });
  };

  /**
   * Choose Chime Function
   * Sets the selected chime audible
   *
   * @param   {event}  e  Event
   */
  handleChooseChime = (e) => {
    let chime = new Audio(e.value);
    chime.play();
    this.setState({ selectedChime: e });
  };

  /* ------------------- Display Board Only Mode */
  manualCall = (ball) => {
    let board = this.state.board;
    let currentBall = null;
    let previousBall = this.currentBall;
    let totalBallsCalled = this.totalBallsCalled;
    let previousCallList = [...this.state.previousCallList];
    Object.keys(board).forEach((letter) => {
      board[letter].forEach((number) => {
        number.active = false;
        if (ball.number === number.number) {
          if (number.called) {
            number.called = false;
            totalBallsCalled--;
            previousCallList = previousCallList.map((previousBall) => {
              return previousBall !== ball;
            });
            previousBall = previousCallList[previousCallList.length - 1];
          } else {
            previousCallList.push(number);
            number.called = true;
            number.active = true;
            totalBallsCalled++;
            currentBall = number;
          }
        }
        return number;
      });
      return letter;
    });
    this.totalBallsCalled = totalBallsCalled;
    this.previousBall = previousBall;
    this.currentBall = currentBall;
    this.setState({ board: board, previousCallList });
  };

  valuetext = (value) => {
    return `${value}`;
  };

  reset_current_voice = () => {
    try {
      this.setState({ playing_sound: null });
    } catch (error) {}
  };

  /**
   * Sends an email that contains game
   * settings and device info to help with
   * replicating user issues
   */
  handleBugReport = () => {
    let subject = "Let's Play Bingo bug report";
    let body = `Thank you for playing let's play bingo and for taking the time to report a bug! Please describe what is happening to you so I may fix it ASAP.`;
    body += `%0D%0A%0D%0A%0D%0A -------------------------------- PLEASE LEAVE EVERYTHING BELOW THIS LINE IN TACT --------------------------------`;
    body += `%0D%0A%0D%0A The data below includes information about your device and your game settings. This information will help me replicate your issue so I can fix it.`;
    body += `%0D%0A%0D%0A----- Browser/Device Info ------ %0D%0A`;
    const { userAgent } = navigator;
    body += JSON.stringify(userAgent);
    body += `%0D%0A%0D%0A----- Game State ------ %0D%0A`;
    let gameData = this.state;
    body += JSON.stringify(gameData);
    window.open(`mailto:hello@letsplaybingo.io?subject=${subject}&body=${body}`);
  };

  /* ------------------- Render */
  render() {
    return (
      <div className="light-links" style={{ height: '100%' }}>
        {/* ----------- Bingo Board ------------- */}
        <section className="board-block">
          <div className="container row no-wrap align-stretch">
            {/* ------ Board ------- */}
            <div className="col pattern-side shrink padding-xlg">
              {/* -------- Digital Displays --------- */}
              <div className="row no-wrap margin-bottom-lg justify-space-between white-text">
                <div className="col text-center margin-sm">
                  <div className="callNumber notranslate">{this.numberDisplay}</div>
                  <div className="callNumber-text uppercase">Total Calls</div>
                </div>
                <div className="col text-center margin-sm">
                  <div className="callNumber notranslate">{this.previousCallDisplay}</div>
                  <div className="callNumber-text uppercase">Previous Call</div>
                </div>
              </div>

              {/* -------- Pattern --------- */}

              {this.state.displayPattern && <SelectedPatternDisplay />}
              {this.state.usePattern && (
                <Pattern pattern={this.state.selectedPattern} update={this.handleUpdatePattern} />
              )}
              {!this.state.usePattern && !this.state.displayPattern && (
                <div className="game-controls">
                  <div className="container row justify-start align-start">
                    <div className="col min-size-25 padding-horizontal-md notranslate">{this.currentBallDisplay}</div>
                  </div>
                </div>
              )}
              <div className="padding-vertical-lg">
                <CartelaNumberField
                  check_result_field_active={this.state.check_result_field_active}
                  call_list={this.state.previousCallList}
                  use_pattern_selected={this.state.usePattern}
                  reset_current_voice={this.reset_current_voice}
                />
              </div>
            </div>
            <div className="col board-side">
              <BingoBoard
                board={this.state.board}
                manualMode={this.state.displayBoardOnly}
                manualCall={this.manualCall}
              />
            </div>
          </div>
        </section>

        {/* ----------- BOTTOM SECTION ------------- */}
        <section className="game-controls dark-bg">
          <div className="container row justify-start align-start">
            {/* ----------- Gameplay Controls ------------- */}
            <div className="col shrink padding-vertical-xxlg padding-horizontal-md">
              <section className="gameplay-controls">
                <div style={{ display: 'flex', gap: '10px', flexWrap: '1' }}>
                  <div>
                    <CartelaSelect
                      enable_cartela_selection={this.state.enable_cartela_selection}
                      show_number_of_players={this.state.manualTrxSave}
                      show_game_type={this.state.showGameType}
                    />
                    <div data-disabled={this.totalBallsCalled >= 75}>
                      <button disabled={this.totalBallsCalled === 0} onClick={this.toggleGame}>
                        {this.state.running ? 'Pause Autoplay' : 'Continue Autoplay'}
                      </button>

                      <button
                        disabled={this.state.manual_call_button_disabled}
                        data-disabled={this.state.displayBoardOnly}
                        data-newgame={this.totalBallsCalled === 0}
                        className={this.state.running ? 'pause-button' : 'play-button'}
                        onClick={this.startNewAutoplayGame}
                        //onClick={this.totalBallsCalled === 0 ? this.startNewAutoplayGameDebounced : this.toggleGame}
                      >
                        Start Autoplay
                      </button>
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={this.toggleResetModal}
                      disabled={!this.state.isFromCacheResume && !this.props.game_is_over && this.totalBallsCalled > 0}
                    >
                      Reset Game
                    </button>

                    <button
                      onClick={this.restartGame}
                      disabled={
                        !this.props.game_is_over && this.totalBallsCalled > 0
                      } /*disabled={this.state.running || this.totalBallsCalled === 0}*/
                    >
                      Restart Game
                    </button>

                    <button
                      onClick={this.readyPlayButtonClicked}
                      disabled={
                        !this.props.game_is_over && this.totalBallsCalled > 0
                      } /*disabled={this.state.running || this.totalBallsCalled > 0}*/
                    >
                      Ready
                    </button>
                    <button onClick={this.shuffleBalls} disabled={this.state.running || this.totalBallsCalled > 0}>
                      Shuffle
                    </button>
                  </div>
                </div>
              </section>
              {this.resetConfirmationModalDisplay}
            </div>

            {/* ----------- Game Settings ------------- */}
            <div className="col grow no-wrap padding-vertical-xxlg padding-horizontal-md white-text">
              <section style={{ display: 'flex', gap: '4px' }} className="game-settings">
                {/* ----------- Gameplay Settings ---------- */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="row align-top justify-start">
                    <div className="col grow min-size-150 padding-horizontal-lg">
                      <div className="row" style={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
                        <div className="col padding-right-xlg">
                          <TextField
                            sx={{
                              input: {
                                textAlign: 'center',
                                fontWeight: 'bolder',
                                fontSize: '48px',
                                boxSizing: 'content-box',
                                padding: '0px',
                              },
                              label: { color: 'black' },
                            }}
                            error=""
                            name="password"
                            label="TOTAL WINNINGS"
                            //type="number"
                            value={
                              this.props.logged_in_user.is_fixed_winning
                                ? this.props.fixed_winning
                                  ? this.props.fixed_winning + ' Birr'
                                  : 0
                                : this.props.total_winnings
                                ? this.props.total_winnings + ' Birr'
                                : 0
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ----------- Settings when using generation ---------- */}

                  {/* ----------- Caller ---------- */}

                  {/*****************show number of players Save********************/}
                  <Stack direction={{ xs: 'column', md: 'row' }}>
                    <div className="row no-wrap align-start justify-start">
                      <div className="col shrink min-size-150 padding-vertical-md padding-horizontal-lg">
                        <h6>Show # Of Players:</h6>
                      </div>

                      <div className="col grow padding-horizontal-lg">
                        <label className={this.state.manualTrxSave ? 'toggle checked' : 'toggle'}>
                          <span className="toggle-span"></span>
                          <span style={{ color: 'black' }}></span>
                          <input
                            type="checkbox"
                            data-gamemode="manual-trx-save"
                            onChange={this.handleCheckbox}
                            checked={this.state.manualTrxSave}
                          ></input>
                        </label>
                      </div>
                    </div>
                    <div className="row no-wrap align-start justify-start">
                      <div className="col shrink min-size-150 padding-vertical-md">
                        <h6>Pattern Selector:</h6>
                      </div>

                      <div className="col grow padding-horizontal-lg">
                        <label className={this.state.usePattern ? 'toggle checked' : 'toggle'}>
                          <span className="toggle-span"></span>
                          <span style={{ color: 'black' }}></span>
                          <input
                            type="checkbox"
                            data-gamemode="pattern-selector"
                            onChange={this.handleCheckbox}
                            checked={this.state.usePattern}
                          ></input>
                        </label>
                      </div>
                    </div>
                    <div className="row no-wrap align-start justify-start">
                      <div className="col shrink min-size-150 padding-vertical-md">
                        <h6>Pattern Display:</h6>
                      </div>

                      <div className="col grow padding-horizontal-lg">
                        <label className={this.state.displayPattern ? 'toggle checked' : 'toggle'}>
                          <span className="toggle-span"></span>
                          <span style={{ color: 'black' }}></span>
                          <input
                            type="checkbox"
                            data-gamemode="display-pattern"
                            onChange={this.handleCheckbox}
                            checked={this.state.displayPattern}
                          ></input>
                        </label>
                      </div>
                    </div>
                    <div className="row no-wrap align-start justify-start">
                      <div className="col shrink min-size-150 padding-vertical-md">
                        <h6>Refund Pattern:</h6>
                      </div>

                      <div className="col grow padding-horizontal-lg">
                        <label className={this.state.useRefundPattern ? 'toggle checked' : 'toggle'}>
                          <span className="toggle-span"></span>
                          <span style={{ color: 'black' }}></span>
                          <input
                            type="checkbox"
                            data-gamemode="refund_pattern_selector"
                            onChange={this.handleCheckbox}
                            checked={this.state.useRefundPattern}
                          ></input>
                        </label>
                      </div>
                    </div>
                  </Stack>
                  <div className="row no-wrap align-center justify-start">
                    <div className="col shrink min-size-150 padding-horizontal-lg">
                      <h6>Autoplay Speed:</h6>
                    </div>
                    <div className="col shrink text-center padding-vertical-lg padding-horizontal-lg">
                      <div className="row no-wrap align-center slider" data-disabled={this.state.displayBoardOnly}>
                        <div className="col shrink padding-right-lg">
                          <span style={{ color: '#090e2c' }}>10s.</span>
                        </div>
                        <div className="col">
                          <Slider
                            min={2000}
                            max={10000}
                            step={1000}
                            value={this.state.delay}
                            onChange={this.handleDelayChange}
                            reverse={true}
                            railStyle={{ background: '#00477a' }}
                          />
                        </div>
                        <div className="col shrink padding-left-lg ">
                          <span style={{ color: '#090e2c' }}>{`${this.state.delay / 1000}s.`}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row no-wrap align-start justify-start">
                    <div className="col shrink min-size-150 padding-vertical-md padding-horizontal-lg">
                      <h6>Game Type:</h6>
                    </div>

                    <div className="col grow padding-horizontal-lg">
                      <label className={this.state.showGameType ? 'toggle checked' : 'toggle'}>
                        <span className="toggle-span"></span>
                        <span style={{ color: 'black' }}></span>
                        <input
                          type="checkbox"
                          data-gamemode="show-game-type"
                          onChange={this.handleCheckbox}
                          checked={this.state.showGameType}
                        ></input>
                      </label>
                    </div>
                  </div>
                </div>

                <div style={{ flexGrow: '1' }}>
                  <CallHistory calledBalls={this.state.previousCallList}></CallHistory>
                </div>
              </section>
            </div>
            {/* ----------- Current Ball Display ------------- */}
            {
              <div className="col min-size-250 padding-vertical-xxlg padding-horizontal-md notranslate">
                {this.currentBallDisplay}
              </div>
            }
          </div>
        </section>

        {this.state.useRefundPattern && (
          <section style={{ display: 'flex', justifyContent: 'space-around' }}>
            <RefundPattern />
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

  //new for resumer
  current_trx_id:state.transactionState.current_trx_id,
  trx_saved:state.transactionState.transaction_info.is_saved,
  //end resumer

  selected_board_ids: state.boards.selected_board_ids,
  disqualified_board_ids: state.boards.disqualified_board_ids,
  logged_in_user: state.auth.user,
  total_winnings: state.transactionState.total_winning,
  fixed_winning: state.transactionState.fixed_winning,
  game_is_over: state.transactionState.game_is_over,
  save_game_type: state.transactionState.game_type,
  save_bet_amount: state.transactionState.bet_amount,
  save_winning_board_id: -1,
  bingo_play_language: state.companies.bingo_play_language,
  _credit:state.credits.credit,
  play_percentage:state.auth.play_percentage
});

const mapDispatchToProps = {
  //new for resumer
  addOrRemoveSelectedBoard,
  set_bet_amount,
  set_game_type,
  set_total_players,
  disqualify_player,
  set_trx_id,
  changeLanguage,
  //end new for resumer
  resetSelectedBoardIds,
  
  reset_winning_info,
  reset_gamePlay_data,
  set_game_is_over,
  set_selected_pattern,
  create_winning_transaction,
  include_disqualified_boards_on_restart,
  reset_winner_lists,
  set_refund_pattern,
  get_credit_for_user
};

export default connect(mapStateToProps, mapDispatchToProps)(BingoGame);
