import { Card, Typography, Stack, Box, Button, Grid } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Iconify from 'src/components/iconify/Iconify';

const CreditTransferDetailCard = ({ 
  transferDetail, 
  transferDetailError, 
  credit,
  currency_formatter,
  onConfirm,
  onCancel,
  height = 'auto' // Allow height customization
}) => {
  return (
    <Card
      elevation={3}
      sx={{
        p: 3,
        borderRadius: 2,
        bgcolor: '#f8f9fa',
        height: height,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {transferDetailError ? (
        <Typography
          variant="h6"
          sx={{
            color: 'error.main',
            p: 2,
            textAlign: 'center',
            bgcolor: 'error.lighter',
            borderRadius: 1,
            mb: 2,
          }}
        >
          {transferDetailError}
        </Typography>
      ) : (
        <>
          <Typography
            variant="body1"
            sx={{
              mb: 3,
              color: 'primary.main',
              borderBottom: '2px solid',
              borderColor: 'primary.light',
              pb: 1,
            }}
          >
            Transfer Details
          </Typography>

          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PhoneIcon sx={{ color: 'text.secondary' }} />
                <Typography variant="body1">
                  <strong>Phone No:</strong> {transferDetail?.phone_no}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Iconify icon="mdi:account" sx={{ color: 'text.secondary' }} />
                <Typography variant="body1">
                  <strong>Name:</strong> {`${transferDetail?.full_name} (${transferDetail?.role})`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Iconify icon="mdi:office-building" sx={{ color: 'text.secondary' }} />
                <Typography variant="body1">
                  <strong>Company Name:</strong> {transferDetail?.companyName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <MonetizationOnIcon sx={{ color: 'text.secondary' }} />
                <Typography variant="body1">
                  <strong>Amount:</strong>
                  {currency_formatter.format(credit) +
                    (transferDetail?.role === 'Adminx' ? ` (+${transferDetail?.cut}%)` : '')}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 'auto',
              pt: 3,
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" color="primary" onClick={onConfirm} startIcon={<Iconify icon="mdi:check" />}>
              Confirm
            </Button>
            <Button variant="outlined" color="error" onClick={onCancel} startIcon={<Iconify icon="mdi:close" />}>
              Cancel
            </Button>
          </Stack>
        </>
      )}
    </Card>
  );
};

export default CreditTransferDetailCard;
