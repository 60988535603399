import React, { useState,useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Paper,
  Button,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { fetchAllGameCredits } from "src/redux/slices/jackpot/jackpotGameCreditSlice";


const GameCreditDetail = () => {

  const dispatch = useDispatch();
 
 
  const {credit_loading,credit_error,scheduledGameCredits} = useSelector((state) => state.jackpotGameCredit);

  const [gamesData, setGamesData] = useState([]);
  const [selectedGame, setSelectedGame] = useState(gamesData[0]?.game_id); // Default to the first game's ID

  useEffect(() => {
    //Fetch game credits for user
    const fetchGameCredits = async () => {
      try {
        await dispatch(fetchAllGameCredits());
      } catch (error) {
        console.error(error);
      }
    };
    fetchGameCredits();
  },[]);

  useEffect(()=>{
    setGamesData(scheduledGameCredits);
  },[scheduledGameCredits]);

  // Get all users for the selected game
  const usersForSelectedGame = gamesData?.filter(
    (game) => game.game_id === selectedGame && game.user_name
  );

  // Get distinct game details (without user fields)
  const distinctGames = [
    ...new Map(
      gamesData?.map((game) => [game.game_id, game])
    ).values(),
  ];

  const handleGameSelection = (gameId) => {
    setSelectedGame(gameId);
  };

  const handleRefresh = async () => {
    await dispatch(fetchAllGameCredits());
  };

  // Calculate total credit for the selected game
  const totalCredit = usersForSelectedGame.reduce(
    (sum, user) => sum + (user.credit || 0),
    0
  );

  return (
    <Grid container spacing={2} sx={{ padding: 3 }}>
      {/* Left Section: List of Games */}
      <Grid item xs={4}>
        <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>
          <Typography variant="h6" gutterBottom>
            Game List
          </Typography>
          {credit_loading && <Typography variant="body2" color="green">Loading...</Typography>}
          {credit_error && <Typography variant="body2" color="error">{credit_error}</Typography>}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleRefresh}
            fullWidth
            sx={{ marginBottom: 2 }}
          >
            Refresh Data
          </Button>
          <List>
            {distinctGames.map((game) => (
              <ListItem
                key={game._id}
                button
                onClick={() => handleGameSelection(game.game_id)}
                selected={selectedGame === game.game_id}
              >
                <ListItemText
                  primary={game.game_name}
                  secondary={`Bet: $${game.bet_amount}`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>

      {/* Right Section: Selected Game Details and Users Table */}
      <Grid item xs={8}>
        <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>
          {/* Game Details */}
          <Typography variant="h6" gutterBottom>
            {distinctGames.find((game) => game.game_id === selectedGame)
              ?.game_name || "No Game Selected"}{" "}
            Details
          </Typography>
          <Typography variant="body1" gutterBottom>
            Game ID: {selectedGame}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Bet Amount: $
            {
              distinctGames.find((game) => game.game_id === selectedGame)
                ?.bet_amount
            }
          </Typography>
          <Divider sx={{ marginY: 2 }} />

          {/* Users Table */}
          {usersForSelectedGame.length > 0 ? (
            <>
              <Typography variant="h6" gutterBottom>
                Users & Credits
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell align="right">Credit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersForSelectedGame.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Avatar sx={{ marginRight: 1 }}>
                              {user.user_name[0].toUpperCase()}
                            </Avatar>
                            {user.user_name}
                          </Box>
                        </TableCell>
                        <TableCell>{user.phone}</TableCell>
                        <TableCell align="right">${user.credit}</TableCell>
                      </TableRow>
                    ))}
                    {/* Total Credit Row */}
                    <TableRow>
                      <TableCell colSpan={3} align="right">
                        <strong>Total Credit:</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>${totalCredit}</strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No users have bought credit for this game.
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GameCreditDetail;
