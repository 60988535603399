import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  MenuItem,
  Typography,
  Menu,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import {
  Phone as PhoneIcon,
  Search as SearchIcon,
  ExpandMore,
  ExpandLess,
  GetApp as GetAppIcon
} from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';



import { useDispatch, useSelector } from 'react-redux';
import { _createJackpotUser, getUserForJackpot,fetchJackpotUsers } from 'src/redux/slices/jackpot/jackpotUserSlice';

const UserTab = ({users_credit}) => {

  const dispatch = useDispatch();

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [role, setRole] = useState('');

  // Example state variables and handler functions
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [agentPhone, setAgentPhone] = useState('');
  const [agentName, setAgentName] = useState('');
  const [subagentPhone, setSubagentPhone] = useState('');
  const [subagentName, setSubagentName] = useState('');
  const [adminPhone, setAdminPhone] = useState('');
  const [adminName, setAdminName] = useState('');
  const [percentage, setPercentage] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [admins,setAdmins] = useState([]);
  const [userPhoneNumberError,setUserPhoneNumberError] = useState('');
  const _logged_in_user = useSelector(state => state.auth.user);

  const showAgentColumns = _logged_in_user.role === 'super' || _logged_in_user.role === 'Agent';
  const showSubAgentColumns = _logged_in_user.role === 'super' || _logged_in_user.role === 'SubAgent' || _logged_in_user.role === 'Agent';
  const showAdminColumns = _logged_in_user.role === 'super'|| _logged_in_user.role === 'SubAgent' || _logged_in_user.role === 'Agent' || _logged_in_user.role === 'Admin';


  //redux state
  const {__users,__loadingUsers,__loadingMessage, __getUserLoading,__getUserMessage,__importedUser,__userSaved,__userSavedMessage} = useSelector(state => state.jackpotUser);
  
  useEffect(()=>{
    if(__importedUser){
        setUserPhoneNumber(__importedUser.phone);
        setName(__importedUser.name);
        setRole(__importedUser.role);
        setAgentPhone(__importedUser.agentPhone);
        setAgentName(__importedUser.agentName);
        setSubagentName(__importedUser.subagentName);
        setSubagentPhone(__importedUser.subAgentPhone);
        setSubagentName(__importedUser.subAgentName);
        setAdmins(__importedUser.admins);
        if(__importedUser.admins && __importedUser.admins.length > 0){
          setAdminName(__importedUser.admins[0].userName);
          setAdminPhone(__importedUser.admins[0].phone);
        }
        setPercentage(50);
    }else{
        clearForm(false);
    }
  },[__importedUser]);

  useEffect(()=>{
    if(__userSaved)
        clearForm(true)
  },[__userSaved]);

  useEffect(()=>{
    dispatch(fetchJackpotUsers());
  },[]);

  const clearForm = (clearPhone) => {
    if(clearPhone)
        setUserPhoneNumber('');

    setName('');
    setRole('');
    setAgentPhone('');
    setAgentName('');
    setSubagentName('');
    setSubagentPhone('');
    setSubagentName('');
    setAdminName('');
    setAdminPhone('');
    setPercentage('')
    setAdmins([]);
    setIsActive(true);

    setIsEditing(false);
  };


  const handleGetUser = () => {
    setUserPhoneNumberError('');
    if(!userPhoneNumber){
        setUserPhoneNumberError('required!');
        return;
    }
    dispatch(getUserForJackpot(userPhoneNumber))
  };

  const handleAddUser = () => {
    // Implementation for adding/updating user data
    setUserPhoneNumberError('');

    if (!userPhoneNumber) {
      setUserPhoneNumberError('phone number required');
      return;
    }

    if (!name) {
      setUserPhoneNumberError('name is required');
      return;
    }

    if (!role) {
      setUserPhoneNumberError('role is required');
      return;
    }

    if (!percentage || percentage <= 0) {
      setUserPhoneNumberError('valid percentage is required');
      return;
    }
    const userObj = {
        phone:userPhoneNumber,
        name,
        role,
        agentPhone,
        agentName,
        subAgentName:subagentName,
        subAgentPhone:subagentPhone,
        adminPhone,
        adminName,
        percentage,
        isActive
    };
    dispatch(_createJackpotUser(userObj));
    //clear when saving is succeded 
    //clearForm(true);
    setIsEditing(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  
  const [isEditing,setIsEditing] = useState(false);

  const handleMenuOpen = (event,user) => {
    console.log(user._id);
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
    setIsEditing(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditUser = () => {
    // Populate form fields with user data
    setUserPhoneNumber(selectedUser.phone);
    setName(selectedUser.name);
    setRole(selectedUser.role);
    setAgentPhone(selectedUser.agentPhone || '');
    setAgentName(selectedUser.agentName || '');
    setSubagentPhone(selectedUser.subAgentPhone || '');
    setSubagentName(selectedUser.subAgentName || '');
    setAdminPhone(selectedUser.adminPhone || '');
    setAdminName(selectedUser.adminName || '');
    setPercentage(selectedUser.percentage);
    setIsActive(selectedUser.isActive);

    // Open the form
    setCollapseOpen(true);
    setIsEditing(true);
    // Close the menu
    handleMenuClose();

    // Scroll to the form
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }


  // Filter users based on search term
  const filteredUsers = __users?.filter((user) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      user.name.toLowerCase().includes(searchLower) ||
      user.phone.toLowerCase().includes(searchLower) ||
      user.role.toLowerCase().includes(searchLower) ||
      (user.agentName && user.agentName.toLowerCase().includes(searchLower)) ||
      (user.subAgentName && user.subAgentName.toLowerCase().includes(searchLower)) ||
      (user.adminName && user.adminName.toLowerCase().includes(searchLower))
    );
  });


  return (
    <>
      {/* Collapsible toggle button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">User Information  {filteredUsers?.length > 0 ? `(${filteredUsers.length} users)` : ``}</Typography>
        {__loadingUsers && (
            <Grid item xs={12}>
              <span style={{ fontSize: '12px', color: 'green' }}>{__loadingMessage} - (user loading)</span>
            </Grid>
          )}
        <IconButton onClick={() => setCollapseOpen(!collapseOpen)}>
          {collapseOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      {/* Collapsible form */}
      <Collapse in={collapseOpen}>
        <Box sx={{ mt: 2, mb: 4 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                helperText={userPhoneNumberError || ''}
                error={!!userPhoneNumberError}
                label="Phone Number"
                value={userPhoneNumber}
                onChange={(e) => setUserPhoneNumber(e.target.value)}
                InputProps={{
                  startAdornment: <PhoneIcon sx={{ mr: 1 }} />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleGetUser}
                startIcon={__getUserLoading ? <CircularProgress size={20} color="inherit" /> : <GetAppIcon />}
                disabled={__getUserLoading || !users_credit || users_credit.credit <= 0}
              >
                Get
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={clearForm}
              >
                Clear
              </Button>
            </Grid>
            {__getUserMessage && (
              <Grid item xs={12}>
                <span style={{ fontSize: '12px', color: 'red' }}>{__getUserMessage}</span>
              </Grid>
            )}
          </Grid>
          {/* Role-dependent form fields */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Name" value={name} onChange={(e) => setName(e.target.value)} required disabled={true}/>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField select fullWidth label="Role" value={role} onChange={(e) => setRole(e.target.value)} required disabled={true}>
                <MenuItem value="Agent">Agent</MenuItem>
                <MenuItem value="SubAgent">Sub Agent</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Employee">Employee</MenuItem>
              </TextField>
            </Grid>

            {/* Show Agent fields for roles that are subagent or higher */}
            {(role === 'SubAgent' || role === 'Admin' || role === 'Employee') && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Agent Phone"
                    value={agentPhone}
                    onChange={(e) => setAgentPhone(e.target.value)}
                    required={role === 'Employee'}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Agent Name"
                    value={agentName}
                    onChange={(e) => setAgentName(e.target.value)}
                    required={role === 'Employee'}
                    disabled={true}
                  />
                </Grid>
              </>
            )}

            {/* Show Subagent fields for roles that are admin or higher */}
            {(role === 'Admin' || role === 'Employee') && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Subagent Phone"
                    value={subagentPhone}
                    onChange={(e) => setSubagentPhone(e.target.value)}
                    required={role === 'Employee'}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Subagent Name"
                    value={subagentName}
                    onChange={(e) => setSubagentName(e.target.value)}
                    required={role === 'Employee'}
                    disabled={true}
                    />
                </Grid>
              </>
            )}

            {/* Show Admin fields for employee role only */}
            {role === 'Employee' && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Admin Phone</InputLabel>
                    <Select
                      value={adminPhone || ''}
                      onChange={(e) => {
                        setAdminPhone(e.target.value);
                        const selectedAdmin = admins?.find(admin => admin.phone === e.target.value);
                        if (selectedAdmin) {
                          setAdminName(selectedAdmin.userName);
                        }
                      }}
                      required
                      disabled={!admins || admins.length === 1}
                    >
                      {admins?.map((admin) => (
                        <MenuItem key={admin.phone} value={admin.phone}>
                          {admin.phone}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/*<TextField
                    fullWidth
                    label="Admin Phone"
                    value={adminPhone}
                    onChange={(e) => setAdminPhone(e.target.value)}
                    required
                    disabled={true}
                    />*/}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Admin Name</InputLabel>
                    <Select
                      value={adminName || ''}
                      onChange={(e) => setAdminName(e.target.value)}
                      required
                      disabled={!admins || admins.length === 1}
                    >
                      {admins?.map((admin) => (
                        <MenuItem key={admin.userName} value={admin.userName}>
                          {admin.userName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                 {/* <TextField
                    fullWidth
                    label="Admin Name"
                    value={adminName}
                    onChange={(e) => setAdminName(e.target.value)}
                    required
                    disabled={true}
                      />*/}
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Percentage"
                type="number"
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
                InputProps={{ endAdornment: '%' }}
                required
                disabled={role === 'Admin' && _logged_in_user.role !== 'super'}/*only super can modify the user percent for admin */
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={<Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
                label="Is Active"
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleAddUser} sx={{ mt: 2 }} disabled={!users_credit || users_credit.credit <= 0}>
            Add User
          </Button>
          {(userPhoneNumberError || __userSavedMessage) && (
            <Grid item xs={12}>
              <span style={{ fontSize: '12px', color: __userSaved ? 'green' : 'red' }}>
                {__userSavedMessage ? __userSavedMessage : userPhoneNumberError}
              </span>
            </Grid>
          )}
          
         
        </Box>
      </Collapse>

      {/* Search and Table Display */}
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="Search Users"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1 }} />,
          }}
        />
      </Box>

<TableContainer component={Paper}> 
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Phone</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Role</TableCell>
        <TableCell>Percentage</TableCell>
        {showAgentColumns && (
          <>
            <TableCell>Ag.Phone</TableCell>
            <TableCell>Ag.Name</TableCell>
          </>
        )}
        {showSubAgentColumns && (
          <>
            <TableCell>Sa.Phone</TableCell>
            <TableCell>Sa.Name</TableCell>
          </>
        )}
        {showAdminColumns && (
          <>
            <TableCell>Ad.Phone</TableCell>
            <TableCell>Ad.Name</TableCell>
          </>
        )}
        <TableCell>Is Active</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredUsers?.map((user, index) => {
        return (
          <TableRow key={index}>
            <TableCell>{user.phone}</TableCell>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.role}</TableCell>
            <TableCell>{user.percentage}%</TableCell>
            {showAgentColumns && (
              <>
                <TableCell>{user.agentPhone}</TableCell>
                <TableCell>{user.agentName}</TableCell>
              </>
            )}
            {showSubAgentColumns && (
              <>
                <TableCell>{user.subAgentPhone}</TableCell>
                <TableCell>{user.subAgentName}</TableCell>
              </>
            )}
            {showAdminColumns && (
              <>
                <TableCell>{user.adminPhone}</TableCell>
                <TableCell>{user.adminName}</TableCell>
              </>
            )}
            <TableCell>
              <Checkbox checked={user.isActive} disabled />
            </TableCell>
            <TableCell>
              <IconButton onClick={(event) => handleMenuOpen(event,user)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl && selectedUser === user)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleEditUser}>
                  Edit
                </MenuItem>
              </Menu>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</TableContainer>
    </>
  );
};

export default UserTab;
