import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gamePlayService from "src/services/gamePlayService";

var gameType = (parseInt(localStorage.getItem('game_type')) > 8 ? 5 : parseInt(localStorage.getItem('game_type'))) || 5;

const initialState = {
    
    transaction_info:{
        is_saved:false,
        is_failed:false,
        is_loading:false,
        save_error_message:null
    },
    bet_amount:0,
    game_type:gameType,
    total_winning:0,
    total_players:0,
    fixed_winning:null,
    game_is_over:false,
    transaction_list:[],
    dynamic_pattern_one:null,
    dynamic_pattern_two:null,

    //this state contains how many winners are found and winner board id lists
    winner_lists :{
        _count:0,
        winning_boards:[]
    },
    current_trx_id:null,
    call_list:[],
    _isOR_Operator:false
}

export const create_winning_transaction = createAsyncThunk(
    'create_winning_transaction',
    async(game_data,thunkAPI)=>{
        try {
            //save game_data service call
            const data = await gamePlayService.create_winning_transaction(game_data);
            //console.log('//save game_data service call');
            if(data){
                return data;
            }else{
                throw "Unable to save trxn";
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }  
    }
);

export const void_transaction = createAsyncThunk(
    'void_transaction',
    async(trx_id,thunkAPI)=>{
        try {
            console.log('trx id is: -> ' + trx_id);
            const data = await gamePlayService.void_transaction(trx_id);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const update_transaction = createAsyncThunk(
    'update_transaction',
    async(trx_data,thunkAPI)=>{
        try {
            const data = await gamePlayService.update_transaction(trx_data);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const gamePlaySlice = createSlice({
  name: 'gamePlaySlice',
  initialState,
  reducers: {
    reset_gamePlay_data: (state, action) => {
      //state.bet_amount = 0;
      //state.fixed_amount = 0;
      //state.game_type = 4;
      state.total_winning = 0;
      state.transaction_info.is_failed = false;
      state.transaction_info.is_loading = false;
      state.transaction_info.is_saved = false;
      state.game_is_over = false;
      state.fixed_winning = null;
    },
    set_bet_amount: (state, action) => {
      //console.log('from slice: ' + action.payload);
      if (state.bet_amount == 0 && action.payload < 0) state.bet_amount = 0;
      else state.bet_amount = action.payload;
    },
    set_game_type: (state, action) => {
      localStorage.removeItem('game_type');
      if (state.game_type == 1 && action.payload < 1) {
        state.game_type = 1;
        localStorage.setItem('game_type', 1);
      } else if (state.game_type == 8 && action.payload > 8) {
        state.game_type = 8;
        localStorage.setItem('game_type', 8);
      } else {
        state.game_type = action.payload;
        
        localStorage.setItem('game_type', action.payload);
      }
      //console.log(state.game_type + ' - ' + action.payload);
    },
    set_total_players: (state, action) => {
      debugger;
      const { _total_players, _saved_game_type, _play_percent } = action.payload;
      state.total_players = _total_players;
      let _game_type = state.game_type;
      let _game_play_multiplier = 5;
      if (_saved_game_type) {
        if (_play_percent && _play_percent > 0) {
          _game_type = _play_percent;
          //_game_play_multiplier = 2;
        }
         
      }
      state.total_winning =
        state.bet_amount * state.total_players - (state.bet_amount * state.total_players * (_game_type * _game_play_multiplier)/100);
    },
    set_trx_id:(state,action)=>{
      state.current_trx_id = action.payload;
    },
    set_fixed_amount: (state, action) => {
      state.fixed_winning = action.payload;
    },
    set_game_is_over: (state, action) => {
      state.game_is_over = action.payload;
      state.current_trx_id = null;
    },
    reset_dynamic_pattern: (state, action) => {
      state.dynamic_pattern_one = null;
      state.dynamic_pattern_two = null;
    },
    set_dynamic_pattern_one: (state, action) => {
      state.dynamic_pattern_one = action.payload;
    },
    set_dynamic_pattern_two: (state, action) => {
      state.dynamic_pattern_two = action.payload;
    },
    set_pattern_operator:(state,action) => {
      console.log('set patern op called!')
      console.log(action.payload)
      state._isOR_Operator = action.payload;
      console.log(state._isOR_Operator)
    },
    set_winner_lists: (state, action) => {
      const sentBoardId = action.payload;
      const newWinningBoards = [...state.winner_lists.winning_boards];
      if (!newWinningBoards.includes(sentBoardId)) {
        newWinningBoards.push(sentBoardId);
      }
      //console.log(newWinningBoards);
      return {
        ...state,
        winner_lists: {
          ...state.winner_lists,
          winning_boards: newWinningBoards,
          _count: newWinningBoards.length,
        },
      };
    },
    reset_winner_lists: (state, action) => {
      return {
        ...state,
        winner_lists: {
          ...state.winner_lists,
          _count: 0,
          winning_boards: [],
        },
      };
    },
  },
  extraReducers: {
    [void_transaction.fulfilled]: (state, action) => {
      //console.log('voiding document completed successfully!');
      return {
        ...state,
        current_trx_id: null,
        winner_lists: {
          ...state.winner_lists,
          _count: 0,
          winning_boards: [],
        },
      };
    },
    [void_transaction.rejected]: (state, action) => {
      //console.log('voiding document threw an error');
      return {
        ...state,
        current_trx_id: null,
        winner_lists: {
          ...state.winner_lists,
          _count: 0,
          winning_boards: [],
        },
      };
    },
    [create_winning_transaction.fulfilled]: (state, action) => {

      state.transaction_list.push(action.payload);
      state.transaction_info.is_saved = true;
      state.transaction_info.is_failed = false;
      state.transaction_info.is_loading = false;
      state.current_trx_id = action.payload._id;

      // console.log('save trx data from slice!');
      // console.log(action.payload);
      // console.log(action.payload._id);
      // console.log('save trx data from slice!');
    },
    [create_winning_transaction.pending]: (state, action) => {
      state.transaction_info.is_loading = true;
      state.transaction_info.is_saved = false;
      state.transaction_info.is_failed = false;
    },
    [create_winning_transaction.rejected]: (state, action) => {
      state.transaction_info.is_saved = false;
      state.transaction_info.is_failed = true;
      state.transaction_info.is_loading = false;
      state.transaction_info.save_error_message = action.payload;
      console.log('trx save failed');
    },
  },
});

export const {
  reset_gamePlay_data,
  set_bet_amount,
  set_game_type,
  set_total_players,
  set_fixed_amount,
  set_game_is_over,
  set_dynamic_pattern_one,
  set_dynamic_pattern_two,
  reset_dynamic_pattern,
  set_winner_lists,
  reset_winner_lists,
  set_pattern_operator,
  set_trx_id
} = gamePlaySlice.actions;



const { reducer } = gamePlaySlice;
export default reducer;