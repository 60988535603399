import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  Typography, 
  Button, 
  Grid, 
  Paper,
  Box,
  Chip,
  Fade,
  useTheme
} from '@mui/material';
import { 
  EmojiEvents as TrophyIcon, 
  AccessTime as ClockIcon, 
  DateRange as CalendarIcon, 
  FlashOn as JackpotIcon ,
  FoodBank as HouseBonusIcon
} from '@mui/icons-material';
import PatternGrid from './patternGrid';
import { useSelector } from 'react-redux';

const adjustToGMT3 = (dateString) => {
  const date = new Date(dateString);
  // Subtract 3 hours to convert to GMT+3
  return new Date(date.getTime() - (3 * 60 * 60 * 1000));
};

const getTimeUntilStart = (startDateTime) => {
  const now = new Date();
  const startTime = adjustToGMT3(startDateTime);
  
  const timeDiff = startTime.getTime() - now.getTime();
  
  if (timeDiff <= 0) {
    return ""; // Game has already started or is over
  }
  
  const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
  const hours = Math.floor((timeDiff / 1000 / 60 / 60) % 24);
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  
  let timeString = '';
  
  if (days > 0) {
    timeString += `${days} day${days > 1 ? 's' : ''}, `;
  }
  
  if (hours > 0) {
    timeString += `${hours} hour${hours > 1 ? 's' : ''}, `;
  }
  
  timeString += `${minutes} minute${minutes > 1 ? 's' : ''}`;
  
  return timeString.trim();
};

const GameDisplay = ({ game }) => {
  const timeUntilStart = getTimeUntilStart(game.startDateTime);
  const startTime = adjustToGMT3(game.startDateTime);
  
  // Convert both dates to Ethiopia timezone (UTC+3) for accurate comparison
  const now = new Date();
  const ethiopiaNow = new Date(now.getTime() - (3 * 60 * 60 * 1000)); // Add 3 hours for UTC+3
  const isGameOver = startTime < ethiopiaNow;
  //const isGameOver = startTime < new Date();
  
  return (
    <div>
      {isGameOver ? (
        `${game.gameName} (over)`
      ) : timeUntilStart ? (
        `${game.gameName} (starts in ${timeUntilStart})`
      ) : (
        game.gameName
      )}
    </div>
  );
};


const GameSelectorComponent = ({ open, handleClose, gameDataList, handleSelect }) => {
  const theme = useTheme();

  //const filteredGameDataList = gameDataList.filter((game) => !game.isDone && !game.isVoid);

  const currentTime = new Date(); // Get the current time
  const threeHoursAgo = new Date(currentTime.getTime() - 3 * 60 * 60 * 1000); // Subtract 3 hours in milliseconds

  const filteredGameDataList = gameDataList.filter((game) => {
    const gameDateTime = new Date(game.startDateTime); // Convert the game's datetime to a Date object
    return (
      !game.isDone && // Include only games that are not done
      !game.isVoid && // Include only games that are not void
      gameDateTime > threeHoursAgo // Include only games with datetime after three hours ago
    );
  });

  const latestGameTime = useSelector((state) => state.jackpotGame.latestGameTime);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
    >
      <DialogTitle
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
          color: 'white',
          padding: theme.spacing(3),
          textAlign: 'center',
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          {`Today's Games (${filteredGameDataList?.length ?? 0}) `}
          <CalendarIcon sx={{ ml: 1 }} />
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: theme.spacing(4) }}>
        <Grid container spacing={3}>
          {[...filteredGameDataList]
            ?.sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime())
            ?.map((game, index) => (
              <Grid item xs={12} key={index}>
                <Fade in={true} timeout={500 + index * 100}>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: theme.spacing(3),
                      borderRadius: theme.shape.borderRadius,
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: theme.shadows[10],
                      },
                    }}
                  >
                    <Grid container alignItems="center" spacing={2}>
                      {/* Game Info Section */}
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
                          <GameDisplay game={game} />
                        </Typography>

                        <Box display="flex" alignItems="center" mb={1}>
                          <TrophyIcon color="action" sx={{ mr: 1 }} />
                          <Typography variant="body1">
                            <strong>Bet Amount:</strong> ${game.betAmount}
                          </Typography>
                        </Box>

                        <Box display="flex" alignItems="center" mb={1}>
                          <TrophyIcon color="success" sx={{ mr: 1 }} />
                          <Typography variant="body1">
                            <strong>Total Winnings:</strong> ${game.totalWinning}
                          </Typography>
                        </Box>

                        {(game.houseBonus > 0) && (
                          <Box display="flex" alignItems="center" mb={1}>
                            <HouseBonusIcon color="success" sx={{ mr: 1 }} />
                            <Typography variant="body1">
                              <strong>House Bonus:</strong> ${game.houseBonus}
                            </Typography>
                          </Box>
                        )}

                        <Box display="flex" alignItems="center" mb={1}>
                          <ClockIcon color="action" sx={{ mr: 1 }} />
                          <Typography variant="body1">
                            <strong>Start Time:</strong> {game.startTime}
                          </Typography>
                        </Box>

                        <Box display="flex" alignItems="center" mb={1}>
                          <CalendarIcon color="action" sx={{ mr: 1 }} />
                          <Typography variant="body1">
                            <strong>Start Date:</strong> {new Date(game.startDateTime).toLocaleDateString()}
                          </Typography>
                        </Box>

                        {game.hasJackpot && (
                          <Box display="flex" alignItems="center">
                            <JackpotIcon color="secondary" sx={{ mr: 1 }} />
                            <Typography variant="body1" color="secondary" fontWeight="bold">
                              { game.jackpotWinning?.map((amount, index) => `jackpot${index + 1} - ${amount}`).join(', ')}
                            </Typography>
                          </Box>
                        )}
                      </Grid>

                      {/* Pattern Grid Section */}
                      <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center">
                        <PatternGrid grid={game.pattern?.grid} editable={false} size={20} />
                      </Grid>

                      {/* Select Button Section */}
                      <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSelect(game.gameId)}
                          size="large"
                          sx={{
                            padding: '10px 20px',
                            fontSize: '1rem',
                            borderRadius: theme.shape.borderRadius,
                            textTransform: 'none',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                              backgroundColor: theme.palette.primary.dark,
                            },
                          }}
                          disabled={new Date(game.startDateTime) < new Date().setUTCHours(new Date().getUTCHours())}
                        >
                          {game.isDone ? 'Game Over' : game.isRunning ? 'Game Running' : 'Select Game'}
                        </Button>
                      </Grid>
                    </Grid>

                    {game.hasJackpot && (
                      <Chip icon={<JackpotIcon />} label="Jackpot Winner" color="secondary" sx={{ mt: 2 }} />
                    )}
                  </Paper>
                </Fade>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GameSelectorComponent;
