import { io } from 'socket.io-client';

let socket = null;

export const initiateSocketConnection = () => {
    if (!socket) {
        const socketUrl = process.env.REACT_APP_SOCKET_URL;
        socket = io(socketUrl, { transports: ['websocket'] });

        //console.log(`Connecting to socket at ${socketUrl}`);

        socket.on('connect', () => {
            //console.log('Socket connected:', socket.id);
        });

        socket.on('disconnect', () => {
            //console.log('Socket disconnected');
        });
    }
    return socket;
};

export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
        //console.log('Socket disconnected');
    }
};

export const subscribeToEvent = (eventName, callback) => {
    if (!socket) {
        //console.log('Socket is null ...');
        return () => {}; // Return a no-op function if the socket is not initialized
    }

    const handler = (data) => {
        ////console.log(`Event received: ${eventName}`, data);
        callback(data);
    };

    socket.on(eventName, handler);

    // Return the unsubscribe function
    return () => {
        //console.log(`Unsubscribing from event: ${eventName}`);
        socket.off(eventName, handler); // Unsubscribe from the event
    };
};

export const emitEvent = (eventName, data) => {
    if (socket) {
        socket.emit(eventName, data);
        //console.log(`Event emitted: ${eventName}`, data);
    }
};
