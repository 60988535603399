import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getToken = () => {
  const token = localStorage.getItem("bingo_access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return config;
};

const createOrUpdateCredit = async (creditData) => {
  try {
    const response = await axiosInstance.post(
      `jackpot/game-credit`,
      creditData,
      getToken()
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const fetchCreditsByPhone = async (phone) => {
  try {
    const response = await axiosInstance.get(
      `jackpot/game-credit/phone/${phone}`,
      getToken()
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const fetchCreditByPhoneAndGame = async (phone, game_id) => {
  try {
    const response = await axiosInstance.get(`jackpot/game-credit/ph-game/${phone}/${game_id}`,
      getToken()
    );
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const fetchCreditsByGame = async (game_id) => {
  try {
    const response = await axiosInstance.get(
      `jackpot/game-credit/game/${game_id}`,
      getToken()
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

///game-credit/user-credit/

const fetchGameCreditsForUser = async (phone) => {
  try {
    const response = await axiosInstance.get(
      `jackpot/game-credit/user-credit/${phone}`,
      getToken()
    );
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const fetchScheduledGameCredits = async () => {
  try {
    const response = await axiosInstance.get(
      `jackpot/game-credit/fetch_credits_for_games`,
      getToken()
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};


export {
  createOrUpdateCredit,
  fetchCreditsByPhone,
  fetchCreditByPhoneAndGame,
  fetchCreditsByGame,
  fetchGameCreditsForUser,
  fetchScheduledGameCredits
};
