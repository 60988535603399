// slices/jackpotGameSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jackpotGameService from '../../../services/jackpot/jackpotGameService';
import jackpotCreditService from "src/services/jackpot/jackpotCreditService";

// Async thunks
export const fetchAllGames = createAsyncThunk(
  'jackpotGame/fetchAllGames',
  async (_, { rejectWithValue }) => {
    try {
      const response = await jackpotGameService.fetchAllGames();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchGameById = createAsyncThunk(
  'jackpotGame/fetchGameById',
  async (gameId, { rejectWithValue }) => {
    try {
      const response = await jackpotGameService.fetchGameById(gameId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchGamesForToday = createAsyncThunk(
  'jackpotGame/fetchGamesForToday',   
  async (criteria, { rejectWithValue }) => {
    try {
      if (!criteria) {
        criteria = {
          start: null,
          end: null,
          isPaid: null
        };
      }
      const response = await jackpotGameService.fetchGamesForToday(criteria.start, criteria.end, criteria.isPaid);
      //console.log(response);
      return response.data;
    } catch (error) {
        //console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const createOrUpdateGame = createAsyncThunk(
  'jackpotGame/createOrUpdateGame',
  async (gameData, { rejectWithValue }) => {
    try {
      const response = await jackpotGameService.createOrUpdateGame(gameData);
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const cartelaPurchase = createAsyncThunk(
  'jackpotGame/cartelaPurchase',
  async (reqObj, { rejectWithValue }) => {
    try {
      // Remove //console.log in production
      if (process.env.NODE_ENV !== 'production') {
        //console.log(reqObj);
      }

      const response = await jackpotGameService.cartelaPurchase(reqObj);

      // Log response only in development
      if (process.env.NODE_ENV !== 'production') {
        //console.log(response);
      }
      if(!response.success){
        throw new Error(response?.message);
      }

      // Return response data directly
      return response;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);

export const getJackpotCreditByPhone = createAsyncThunk(
  'jackpotCredit/getJackpotCreditByPhone',
  async (phone, { rejectWithValue }) => {
    try {
      var response = await jackpotCreditService.getCreditByPhone(phone);
      ////console.log(response);
      return response;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getLatestJackpotGame = createAsyncThunk(
  'jackpotGame/getLatestJackpotGame',
  async (_, { rejectWithValue }) => {
    try {
      const response = await jackpotGameService.getLatestJackpotGame();
      return response.data;
    }catch(error){
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchLastPlayedJackpotGame = createAsyncThunk(
  'jackpotGame/fetchLastPlayedJackpotGame',
  async (_, { rejectWithValue }) => {
    try {
      const response = await jackpotGameService.getLastGameInfo();
      return response.data;
    }catch(error){
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Initial state
const initialState = {
  __jackpotGames: [],
  __game: null,
  __todayGames: [],
  __loading: false,
  __error: null,
  __saved:null,

  __purchase_game_id:null,
  __purchase_loading:null,
  __purchase_error:null,

  __users_credit:null,
  __user_credit_message:null,

  __latest_game:null,
  __latest_game_error:null,

  __last_game_info:null,
  __last_game_info_error:null,

  latestGameTime:null,
};

// JackpotGame slice
const jackpotGameSlice = createSlice({
  name: 'jackpotGame',
  initialState,
  reducers: {
    clearPurchaseStatus:(state,action)=>{
      state.__purchase_error = null;
      state.__purchase_game_id = null;
      state.__purchase_loading = null;
    },
    resetSaved:(state,action)=>{
        state.__saved = null;
    },
    reset_purchase_states:(state,action)=>{
      state.__purchase_error = null;
      state.__purchase_game_id = null;
      state.__purchase_loading = null;
    },
    setLatestGameTime(state, action) {
      state.latestGameTime = action.payload; // Update the time
    }
  },
  extraReducers: (builder) => {
    // Fetch all games
    builder
      .addCase(fetchAllGames.pending, (state) => {
        state.__loading = true;
        state.__error = null;
      })
      .addCase(fetchAllGames.fulfilled, (state, action) => {
        state.__loading = false;
        state.__jackpotGames = action.payload;
      })
      .addCase(fetchAllGames.rejected, (state, action) => {
        state.__loading = false;
        state.__error = action.payload;
      });

    // Fetch game by ID
    builder
      .addCase(fetchGameById.pending, (state) => {
        state.__loading = true;
        state.__error = null;
      })
      .addCase(fetchGameById.fulfilled, (state, action) => {
        state.__loading = false;
        state.__game = action.payload;
      })
      .addCase(fetchGameById.rejected, (state, action) => {
        state.__loading = false;
        state.__error = action.payload;
      });

    // Fetch games for today
    builder
      .addCase(fetchGamesForToday.pending, (state) => {
        state.__loading = true;
        state.__error = null;
      })
      .addCase(fetchGamesForToday.fulfilled, (state, action) => {
        state.__loading = false;
        state.__error = null;
      
        
        state.__todayGames = action.payload;
      
        ////console.log(state.__todayGames);
      })
      .addCase(fetchGamesForToday.rejected, (state, action) => {
        //console.log(action.payload.data);
        state.__loading = false;
        state.__error = action.payload?.message;
      });

    // Create or update game
    builder
      .addCase(createOrUpdateGame.pending, (state) => {
        state.__loading = true;
        state.__error = null;
      })
      .addCase(createOrUpdateGame.fulfilled, (state, action) => {
        state.__loading = false;
        state.__saved = true;

        const updatedGame = action.payload;
        const existingGameIndex = state.__todayGames.findIndex((game) => game.gameId === updatedGame.gameId);

        if (existingGameIndex !== -1) {
          // Update existing game
          
          state.__todayGames = state.__todayGames.map((game) =>
            game.gameId === updatedGame.gameId ? { ...game, ...updatedGame } : { ...game }
          );
        } else {
          
          state.__todayGames = [...state.__todayGames, { ...updatedGame }];
        }
        
      }).
      addCase(createOrUpdateGame.rejected, (state, action) => {
        state.__loading = false;
        //console.log(action.payload);
        state.__error = action.payload?.message;
      });

      builder
      .addCase(cartelaPurchase.pending,(state, action)=>{
        state.__purchase_error=null;
        state.__purchase_loading = true;
      })
      .addCase(cartelaPurchase.fulfilled,(state, action)=>{
        state.__purchase_error=null;
        state.__purchase_loading = null;
        
        state.__purchase_game_id = action.payload.data.game;
        state.__users_credit = action.payload.data.newCredit;
      })
      .addCase(cartelaPurchase.rejected,(state, action)=>{
        state.__purchase_error=action.payload;
        state.__purchase_loading = null;
      })
      .addCase(getJackpotCreditByPhone.fulfilled,(state, action)=>{
        state.__users_credit=action.payload?.data;
        state.__user_credit_message=null;
      })
      .addCase(getJackpotCreditByPhone.rejected,(state, action)=>{
        state.__users_credit=null;
        state.__user_credit_message=action.payload.message;

      })
      .addCase(getLatestJackpotGame.pending,(state, action)=>{
        state.__latest_game_error=null;
        state.__latest_game=null;
        //console.log('pending');
      })  
      .addCase(getLatestJackpotGame.fulfilled,(state, action)=>{
        state.__latest_game_error=null;
        state.__latest_game=action.payload;
        //console.log(action.payload);
      })
      .addCase(getLatestJackpotGame.rejected,(state, action)=>{
        state.__latest_game_error=action.payload?.message;
        state.__latest_game=null;
        //console.log(action.payload);
      })
      .addCase(fetchLastPlayedJackpotGame.pending,(state, action)=>{
        state.__last_game_info_error=null;
        state.__last_game_info=null;
      })  
      .addCase(fetchLastPlayedJackpotGame.fulfilled,(state, action)=>{
        state.__last_game_info_error=null;
        state.__last_game_info=action.payload;
      })
      .addCase(fetchLastPlayedJackpotGame.rejected,(state, action)=>{
        state.__last_game_info_error=action.payload?.message;
        state.__last_game_info=null;
      }); 
  
  },
});

export const {resetSaved,reset_purchase_states,setLatestGameTime} = jackpotGameSlice.actions;
export default jackpotGameSlice.reducer;
