import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createOrUpdateCredit,
  fetchCreditsByPhone,
  fetchCreditByPhoneAndGame,
  fetchCreditsByGame,
  fetchGameCreditsForUser,
  fetchScheduledGameCredits
} from "src/services/jackpot/jackpotGameCreditService";


// Async thunks
export const getGameCreditsForUser = createAsyncThunk(
  "gameCredits/getGameCreditsForUser",
  async (phone, { rejectWithValue }) => {
    try {
      return await fetchGameCreditsForUser(phone);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrUpdateGameCredit = createAsyncThunk(
  "gameCredits/createOrUpdate",
  async (creditData, { rejectWithValue }) => {
    try {
      return await createOrUpdateCredit(creditData);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCreditsByPhone = createAsyncThunk(
  "gameCredits/getByPhone",
  async (phone, { rejectWithValue }) => {
    try {
      return await fetchCreditsByPhone(phone);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCreditByPhoneAndGame = createAsyncThunk(
  "gameCredits/getByPhoneAndGame",
  async ({ phone, gameId }, { rejectWithValue }) => {
    try {
      
      return await fetchCreditByPhoneAndGame(phone, gameId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCreditsByGame = createAsyncThunk(
  "gameCredits/getByGame",
  async (game_id, { rejectWithValue }) => {
    try {
      return await fetchCreditsByGame(game_id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllGameCredits = createAsyncThunk(
  "gameCredits/fetchAllGameCredits",
  async (_,{rejectWithValue})=>{
    try {
      return await fetchScheduledGameCredits();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

// Slice definition
const gameCreditSlice = createSlice({
  name: 'gameCredits',
  initialState: {
    credits: [],
    gameCredit:null,
    userGameCredits: [],
    scheduledGameCredits:[],
    loading: false,
    error: null,
    saveMsg:null,

    credit_loading: false,
    credit_error: null,
  },
  reducers: {
    resetCreditStatus: (state) => {
      state.error = null;
      state.saveMsg = null;
    }
  },
  extraReducers: (builder) => {
    // Create/Update Credit
    builder
      .addCase(createOrUpdateGameCredit.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.saveMsg = null;
      })
      .addCase(createOrUpdateGameCredit.fulfilled, (state, action) => {
        
        state.loading = false;
        state.credits = action.payload;
        state.saveMsg = 'saved successfully';
        state.error = null;
        
      })
      .addCase(createOrUpdateGameCredit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
        state.saveMsg = null;
        state.credits = [];
      });

    // Fetch Credits by Phone
    builder
      .addCase(getCreditsByPhone.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCreditsByPhone.fulfilled, (state, action) => {
        state.loading = false;
        state.credits = action.payload;
      })
      .addCase(getCreditsByPhone.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });

    // Fetch Credit by Phone and Game
    builder
      .addCase(getCreditByPhoneAndGame.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.gameCredit = null;
      })
      .addCase(getCreditByPhoneAndGame.fulfilled, (state, action) => {
        state.loading = false;
        state.gameCredit = action.payload?.data;
        console.log(state.gameCredit);
      })
      .addCase(getCreditByPhoneAndGame.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
        state.gameCredit = null;
      });

    // Fetch Credits by Game
    builder
      .addCase(getCreditsByGame.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCreditsByGame.fulfilled, (state, action) => {
        state.loading = false;
        state.credits = action.payload;
      })
      .addCase(getCreditsByGame.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });

    // Fetch Credits by phone for user
    builder
      .addCase(getGameCreditsForUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGameCreditsForUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userGameCredits = action.payload.data;
      })
      .addCase(getGameCreditsForUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
        state.userGameCredits = [];
      })
      .addCase(fetchAllGameCredits.pending, (state) => {
        state.credit_loading = true;
        state.credit_error = null;
      })
      .addCase(fetchAllGameCredits.fulfilled, (state, action) => {
        state.credit_loading = false;
        state.credit_error = null;
        state.scheduledGameCredits = action.payload?.data;
        console.log(action.payload);
      }).
      addCase(fetchAllGameCredits.rejected, (state, action) => {
        state.credit_loading = false;
        state.credit_error = action.payload?.message;
        state.scheduledGameCredits = [];
      });
  },
});

export const { resetCreditStatus } = gameCreditSlice.actions;
export default gameCreditSlice.reducer;
