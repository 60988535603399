import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Chart from "react-apexcharts";
import { useSelector,useDispatch } from "react-redux";
import { jackpotDashboardData } from 'src/redux/slices/jackpot/jackpotDashboardSlice';

const JackpotDashboard = () => {
    
  const dispatch = useDispatch();
  const _logged_in_user = useSelector((state) => state.auth.user);
  const {gameData,creditDistributionData,dashboardStatus,summerizedDashboardStatus,summerizedDashboardData} = useSelector(state => state.jackpotDashboardData);
  
  // New state for date range
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredGameData, setFilteredGameData] = useState(gameData);
  const [filteredCreditData, setFilteredCreditData] = useState(creditDistributionData);
  const [selectedGame, setSelectedGame] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(()=>{
    setFilteredGameData(gameData);
    setFilteredCreditData(creditDistributionData);
  },[gameData,creditDistributionData]);

  // Filter function
  const handleFilter = async () => {
    /*if (!startDate || !endDate) {
      setFilteredGameData(gameData);
      setFilteredCreditData(creditDistributionData);
      return;
    }*/

    const start = new Date(startDate).toISOString();
    const end = new Date(endDate).toISOString();

    await dispatch(jackpotDashboardData({start:start,end:end}));
    

    // Filter game data
    /*const filteredGames = gameData.filter(game => {
      const gameDate = new Date(game.startDateTime);
      return gameDate >= start && gameDate <= end;
    });

    // Filter credit distribution data
    const filteredCredits = creditDistributionData.filter(credit => {
      const creditDate = new Date(credit.date);
      return creditDate >= start && creditDate <= end;
    });

    setFilteredGameData(filteredGames);
    setFilteredCreditData(filteredCredits);*/
  };

  // Calculate summaries based on filtered data
  const calculateSummaries = () => {

    const now = new Date();
    const today = now.toISOString().split('T')[0];
    const thisWeek = new Date(now.setDate(now.getDate() - 7)).toISOString();
    const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
    const thisYear = new Date(now.getFullYear(), 0, 1).toISOString();

    return {
      daily: filteredCreditData
        .filter(item => item.date === today)
        .reduce((acc, item) => acc + item.amount, 0),
      weekly: filteredCreditData
        .filter(item => new Date(item.date) >= new Date(thisWeek))
        .reduce((acc, item) => acc + item.amount, 0),
      monthly: filteredCreditData
        .filter(item => new Date(item.date) >= new Date(thisMonth))
        .reduce((acc, item) => acc + item.amount, 0),
      yearly: filteredCreditData
        .filter(item => new Date(item.date) >= new Date(thisYear))
        .reduce((acc, item) => acc + item.amount, 0)
    };
  };

  const summaries = calculateSummaries();

  // Chart configurations remain the same but use filtered data
  const distributionChartOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    xaxis: {
      categories: [...new Set(filteredCreditData.map(item => item.fromBranch))],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Math.round(value); // Round the y-axis values
        },
      },
    },
    title: {
      text: "Distribution by Branch",
      align: "center",
    },
  };
  
  const distributionSeries = [{
    
    name: "Amount",
    data: Object.values(filteredCreditData.reduce((acc, item) => {
      const amount = Math.round(item.amount * 100) / 100; // Create a rounded amount
      acc[item.fromBranch] = (acc[item.fromBranch] || 0) + amount; // Add to the accumulator
      return acc;
    }, {}))
  }];

  const depositStatusOptions = {
    chart: {
      type: "pie",
      height: 350,
    },
    labels: ["Deposited", "Pending"],
    title: {
      text: "Deposit Status Distribution",
      align: "center",
    },
  };

  const depositStatusSeries = [
    filteredCreditData.filter(item => item.deposited).length,
    filteredCreditData.filter(item => !item.deposited).length
  ];

  return (
    <Box sx={{ padding: 4 }}>
        {dashboardStatus && <h6>{dashboardStatus}</h6>}
      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 3, textAlign: "center" }}>
            <Typography variant="subtitle1" color="textSecondary">Daily Total</Typography>
            <Typography variant="h4">${summerizedDashboardData?.daily || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 3, textAlign: "center" }}>
            <Typography variant="subtitle1" color="textSecondary">Weekly Total</Typography>
            <Typography variant="h4">${summerizedDashboardData?.weekly || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 3, textAlign: "center" }}>
            <Typography variant="subtitle1" color="textSecondary">Monthly Total</Typography>
            <Typography variant="h4">${summerizedDashboardData?.monthly || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 3, textAlign: "center" }}>
            <Typography variant="subtitle1" color="textSecondary">Yearly Total</Typography>
            <Typography variant="h4">${summerizedDashboardData?.yearly || 0}</Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Date Range Filter */}
       <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <TextField
              type="date"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button 
              variant="contained" 
              onClick={handleFilter}
              fullWidth
            >
              Show Results
            </Button>
            {dashboardStatus && <span style={{fontSize:'8px',color:'purple'}}>{dashboardStatus}</span>}
          </Grid>
        </Grid>
      </Paper> 

      {/* Charts */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Chart
              options={distributionChartOptions}
              series={distributionSeries}
              type="bar"
              height={350}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Chart
              options={depositStatusOptions}
              series={depositStatusSeries}
              type="pie"
              height={350}
            />
          </Paper>
        </Grid>
      </Grid>

      {/* Games Table */}
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Scheduled Game Details
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Name</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Bet Amount</TableCell>
                <TableCell align="right">Total Winning</TableCell>
                <TableCell align="right">House Bonus</TableCell>
                <TableCell align="right">Total Players</TableCell>
                <TableCell align="right">Done</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredGameData.map((game) => (
                
                <TableRow key={game.gameId}>
                  <TableCell>{game.gameName}</TableCell>
                  <TableCell>{game.startTime}</TableCell>
                  <TableCell>{game.startDateTime}</TableCell>
                  <TableCell align="right">${game.betAmount}</TableCell>
                  <TableCell align="right">${game.totalWinning}</TableCell>
                  <TableCell align="right">{game.houseBonus?`${game.houseBonus}`:0}</TableCell>
                  <TableCell align="right">{game.totalPlayers}</TableCell>
                  <TableCell align="right">{game.isDone? 'True' : 'False'}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setSelectedGame(game);
                        setOpenDialog(true);
                      }}
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Credit Distribution Grid */}
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Credit Distribution
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game ID</TableCell>
                <TableCell>Game Date</TableCell>
                <TableCell align="right">Total Amount</TableCell>
                <TableCell align="center">Deposit Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCreditData.map((credit, index) => (
                <TableRow key={index}>
                  <TableCell>{credit.game_id}</TableCell>
                  <TableCell>{credit.date}</TableCell>
                  <TableCell align="right">${credit.amount}</TableCell>
                  <TableCell align="center">
                    {credit.deposited ? (
                      <Typography color="success.main">Deposited</Typography>
                    ) : (
                      <Typography color="error.main">Pending</Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Detail Dialog */}
      {/* <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedGame?.gameName} - Player Details
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {_logged_in_user?.role === 'super' && <TableCell>Player Phone</TableCell>}
                  <TableCell>Owner Phone</TableCell>
                  <TableCell>Board ID</TableCell>
                  <TableCell>Owner Branch</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedGame?.players.map((player, index) => (
                  <TableRow key={index}>
                    {_logged_in_user?.role === 'super' && <TableCell>{player.playerPhone}</TableCell>}
                    <TableCell>{player.ownerPhone}</TableCell>
                    <TableCell>{player.boardId}</TableCell>
                    <TableCell>{player.ownerBranch}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog> */}

{(selectedGame && selectedGame != null) && <PlayerDetailsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        selectedGame={selectedGame}
        _logged_in_user={_logged_in_user}
      />}
    </Box>
  );
};

const PlayerDetailsDialog = ({ openDialog, setOpenDialog, selectedGame, _logged_in_user }) => {
  // Group players by branch for the pie chart
  debugger;
  const branchPlayerCounts = selectedGame?.players.reduce((acc, player) => {
    acc[player.ownerBranch] = (acc[player.ownerBranch] || 0) + 1;
    return acc;
  }, {});

  const sortedBranches = Object.entries(branchPlayerCounts || {}).sort(
    ([, countA], [, countB]) => countB - countA
  );

  const pieChartData = {
    series: sortedBranches.map(([, count]) => count), // Counts
    options: {
      chart: {
        type: 'pie',
      },
      labels: sortedBranches.map(([branch]) => branch), // Branch names
      legend: {
        position: 'right',
      },
    },
  };

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth fullScreen>
      <DialogTitle>
        {selectedGame?.gameName} - Player Details
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            
            <Chart
              options={pieChartData.options}
              series={pieChartData.series}
              type="pie"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {_logged_in_user?.role === 'super' && <TableCell>Player Phone</TableCell>}
                    <TableCell>Owner Phone</TableCell>
                    <TableCell>Board ID</TableCell>
                    <TableCell>Owner Branch</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedGame?.players.map((player, index) => (
                    <TableRow key={index}>
                      {_logged_in_user?.role === 'super' && <TableCell>{player.playerPhone}</TableCell>}
                      <TableCell>{player.ownerPhone}</TableCell>
                      <TableCell>{player.boardId}</TableCell>
                      <TableCell>{player.ownerBranch}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};


export default JackpotDashboard;