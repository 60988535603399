import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import {getJackpotDashboardData,fetchSummerizedDashboardData} from 'src/services/jackpot/jackpotDashboardService';

export const jackpotDashboardData = createAsyncThunk(
    'jackpotDashboard/getdata',
    async (reqData, { rejectWithValue }) => {
      try {
        const {start,end} = reqData;
        const response = await getJackpotDashboardData(start,end);
        //////console.log(response.data)
        return response;
      } catch (error) {
        ////console.log(error)
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getSummerizedDashboardData = createAsyncThunk(
    'jackpotDashboard/getSummerizedDashboardData',
    async (_, { rejectWithValue }) => {
      try {
        const response = await fetchSummerizedDashboardData();
        //console.log(response)
        return response;
      } catch (error) {
        //console.log(error)
        return rejectWithValue(error.response.data);
      }
    }
  );

  const initialState = {
    gameData:[],
    creditDistributionData:[],
    dashboardStatus:null,

    summerizedDashboardData:null,
    summerizedDashboardStatus:null,
};

  const jackpotDashboardSlice = createSlice({
    name:'jackpotDashboard',
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(jackpotDashboardData.fulfilled,(state,action)=>{
            const result = action.payload;
            state.gameData = result.data?.game??[];
            state.creditDistributionData = result.data?.credit??[];
            state.dashboardStatus = null;
        })
        .addCase(jackpotDashboardData.pending,(state,action)=>{
            state.dashboardStatus='Loading dashboard ...'
        })
        .addCase(jackpotDashboardData.rejected,(state,action)=>{
            state.dashboardStatus = action.payload?.message;
            state.gameData = [];
            state.creditDistributionData = [];
        })
        .addCase(getSummerizedDashboardData.pending,(state,action)=>{
          state.summerizedDashboardStatus = "Loading Summary...";
        })
        .addCase(getSummerizedDashboardData.rejected,(state,action)=>{
          state.summerizedDashboardStatus = action.payload?.message || 'error loading summary';
        })
        .addCase(getSummerizedDashboardData.fulfilled,(state,action)=>{
          //console.log(action.payload);
          state.summerizedDashboardData = action.payload?.data;
          state.summerizedDashboardStatus = null;
        });
    }
  });

  export default jackpotDashboardSlice.reducer;