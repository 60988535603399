import './MovingText.css';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLatestJackpotGame } from 'src/redux/slices/jackpot/jackpotGameplaySlice';

// ... existing imports ...

export default function MovingText() {
    const dispatch = useDispatch();
    const latestGame = useSelector((state) => state.jackpotGame.__latest_game);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [countdown, setCountdown] = useState('');
    const [nextGameFetched, setNextGameFetched] = useState(false);
    const latestGameTime = useSelector((state) => state.jackpotGame.latestGameTime);
    
  useEffect(() => {
    // Fetch the latest game initially
    //dispatch(getLatestJackpotGame());
    console.log('latestGameTime',latestGameTime);
  }, []);

  useEffect(() => {
    if (latestGame?.startDateTime) {
      const updateTimer = () => {
       
        const startTime = new Date(new Date(latestGame.startDateTime).getTime());
        const now = new Date();
        const diff = startTime - now;

        const minutesRemaining = Math.floor(diff / (1000 * 60));
        const secondsRemaining = Math.floor((diff / 1000) % 60);

        if (minutesRemaining <= 15 && minutesRemaining >= 0) {
          setTimeRemaining(minutesRemaining);
          setCountdown(`${minutesRemaining}:${secondsRemaining.toString().padStart(2, '0')}`);
        } else {
          setTimeRemaining(null);
          setCountdown('');
        }

        // When the timer elapses, attempt to fetch the next game
        if (minutesRemaining < 0 && !nextGameFetched) {
          console.log('Fetching the next game');
          setNextGameFetched(true);
          dispatch(getLatestJackpotGame());
        }
      };

      updateTimer();
      const timer = setInterval(updateTimer, 1000); // Update every second

      return () => clearInterval(timer);
    }
  }, [latestGame, dispatch, nextGameFetched]);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="marquee">
                <span className='main'>
                    {timeRemaining ? 
                        `Next Game Starts in ${timeRemaining} minutes!. ሃገሬ ጌምስ.` :
                        // 'Hagere Games. ሃገሬ ጌምስ. Hagere Games.'
                        `
                        ሐገሬ ጌምስ. ዘወትር ማክሰኞ እና ቅዳሜ ከምሽቱ 12፡30 የሐገሬ ጃክፖት ካርቴላ በመግዛት እስከ 20,000 ብር ያሸንፉ!  
                        ለበለጠ መረጃ ወኪሎትን ያናግሩ ወይም "Hagere Jackpot" የሚለውን መለያ ይምረጡ! ሐገሬ ጌምስ!`
                    }
                </span>
            </div>

            {latestGameTime && (parseInt(latestGameTime.timeString?.split(':')[0]) === 0 
            && parseInt(latestGameTime.timeString?.split(':')[1]) < 16) && (
                <div style={{
                    marginLeft: '20px',
                    minWidth: '100px', 
                    fontSize: '18px',
                    fontWeight: 'bold',
                    fontFamily: 'digital-7, monospace',
                    color: '#ff0000',
                    padding: '10px',
                    borderRadius: '8px',
                    background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    display: 'flex',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    transition: 'transform 0.2s',
                    cursor: 'default',
                    '&:hover': {
                        transform: 'translateY(-2px)'
                    }
                }}>
                    {latestGameTime.timeString}
                </div>
            )}
            
            
            
        </div>
    );
}