import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import CardSelector from '../CardSelector';
import { useDispatch } from 'react-redux';
import { get_all_boards, resetSelectedBoardIds,addOrRemoveSelectedBoard } from 'src/redux/slices/boardSlice';
import {getPlayPercentage,getBetAmount} from 'src/redux/slices/userSlice';
import { useSelector } from 'react-redux';
import Sldr from '@mui/material/Slider';
import { Stack } from '@mui/system';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Iconify from '../../iconify';
import { reset_gamePlay_data, set_bet_amount, set_fixed_amount, set_game_type, set_total_players } from 'src/redux/slices/gamePlaySlice';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 7,
        label: '7',
    },
    {
        value: 8,
        label: '8',
    },
];

function CartelaSelect({enable_cartela_selection,show_number_of_players,show_game_type}) {
  const [open, setOpen] = React.useState(false);

  const logged_in_user = useSelector((state) => state.auth.user);
  const bet_amount = useSelector((state) => state.transactionState.bet_amount);

  const game_type = useSelector((state) => state.transactionState.game_type);

  const game_typex = useSelector((state) => state.auth.play_percentage); //play percentage set from admin
  const bet_amountx = useSelector((state) => state.auth.bet_amount); //bet amount set from admin

  const fixed_winning = useSelector((state) => state.transactionState.fixed_winning);
  const selectedBoards = useSelector((state) => state.boards.selected_board_ids);
  const all_boards = useSelector((state) => state.boards.available_playing_boards);

  const [board_number, set_board_number] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getPlayPercent() {
      await dispatch(getPlayPercentage());
      await dispatch(getBetAmount());
    }
    getPlayPercent();
  }, [game_typex, bet_amountx]);

  useEffect(() => {
    dispatch(set_bet_amount(bet_amountx));
  }, [bet_amountx]);

  const handleClickOpen = () => {
    dispatch(get_all_boards(logged_in_user.branch));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    setOpen(false);
    dispatch(reset_gamePlay_data());
    dispatch(resetSelectedBoardIds());
  };

  /*const handleSave = () => {
      //console.log(selectedBoards.length);
        setOpen(false);
        dispatch(set_total_players(selectedBoards.length));
    }*/

  const handleSave = async () => {
    setOpen(false);
    //dispatch(set_total_players(selectedBoards.length));
    if (show_game_type) {
      await dispatch(getPlayPercentage());
    }
    dispatch(
      set_total_players({
        _total_players: selectedBoards.length,
        _saved_game_type: show_game_type,
        _play_percent: game_typex,
      })
    );
  };

  const _handleCartelaKeyDown = async (e) => {
    if (e.key === 'Enter' && board_number > 0) {
      console.log(all_boards);
      if (all_boards?.some((t) => t.board_id == board_number)) {
        if (selectedBoards?.includes(board_number)) {
          var conf = confirm('Board number already selected. Do you want to deselect it?');
          if (conf) {
            dispatch(addOrRemoveSelectedBoard(board_number));
            set_board_number('');
          }
        } else {
          dispatch(addOrRemoveSelectedBoard(board_number));
          set_board_number('');
        }
      } else {
        alert(`cartela number ${board_number} not available!`);
      }
    }
  };

  const handleGameTypeValue = (typeValue) => {
    if (typeValue > 8) {
      // Check if the value is a multiple of 2
      if (typeValue % 2 === 0) {
        return (typeValue * 2) / 5;
      } else {
        // Round to the next multiple of 2 and then perform the operation
        const nextMultipleOfTwo = Math.ceil(typeValue / 2) * 2;
        return (nextMultipleOfTwo * 2) / 5;
      }
    }
    return typeValue; // return the original value if not greater than 12
  };

  return (
    <>
      <button disabled={!enable_cartela_selection} onClick={handleClickOpen}>
        Select Cartela
      </button>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: 'white', color: 'black' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Stack
              direction={'row'}
              sx={{
                padding: '12px',
                gap: '24px',
                width: '100%',
                alignItems: 'center',
                margin: 'auto',
                borderRadius: '10px',
              }}
            >
              <TextField
                sx={{
                  input: { textAlign: 'center', fontWeight: 'bolder', fontSize: '24px', boxSizing: 'content-box' },
                  label: { color: 'rgb(9, 14, 44)' },
                }}
                style={{ display: logged_in_user.is_fixed_winning ? 'none' : 'block' }}
                error=""
                name="gametype"
                label="Game Type"
                type="number"
                min={1}
                max={8}
                value={
                  show_game_type
                    ? handleGameTypeValue(game_type)
                    : game_typex && parseInt(game_typex) > 0
                    ? game_type < game_typex
                      ? handleGameTypeValue(game_typex)
                      : handleGameTypeValue(game_type)
                    : handleGameTypeValue(game_type)
                
              } 
                InputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ color: 'rgb(9, 14, 44)', padding: '0px' }}
                        onClick={() => {
                          if (show_game_type) {
                            dispatch(set_game_type(game_type - 1));
                          } else {
                            if (game_typex && parseInt(game_typex) > 0) {
                              console.log(game_typex)
                              if (game_type <= game_typex) {
                                dispatch(set_game_type(game_typex));
                              } else {
                                dispatch(set_game_type(game_type - 1));
                              }
                            } else {
                              dispatch(set_game_type(game_type - 1));
                            }
                          }
                        }}
                        edge="end"
                      >
                        <Iconify style={{ width: '32px', height: '32px' }} icon="ci:minus-square" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start" sx={{ padding: '0px' }}>
                      <IconButton
                        sx={{ color: 'rgb(9, 14, 44)', padding: '0px' }}
                        onClick={() => dispatch(set_game_type(game_type + 1))}
                        edge="end"
                      >
                        <Iconify style={{ width: '32px', height: '32px' }} icon="material-symbols:add-box" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                sx={{
                  input: { textAlign: 'center', fontWeight: 'bolder', fontSize: '24px', boxSizing: 'content-box' },
                  label: { color: 'rgb(9, 14, 44)' },
                }}
                error=""
                name="bet"
                label="BET"
                type="number"
                onChange={(e) => dispatch(set_bet_amount(e.target.value))}
                value={
                  bet_amountx && parseInt(bet_amountx) > 0
                    ? bet_amount < bet_amountx
                      ? bet_amountx
                      : bet_amount
                    : bet_amount
                } // {bet_amountx == null ? bet_amount < 0 ? 20 : bet_amount : bet_amountx}
                InputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ color: 'rgb(9, 14, 44)', padding: '0px' }}
                        onClick={() => {
                          if (logged_in_user?.betAmount && logged_in_user?.betAmount > 0) {
                            if (bet_amount > logged_in_user?.betAmount) {
                              dispatch(set_bet_amount(parseInt(bet_amount) - 5));
                            }
                          } else {
                            dispatch(set_bet_amount(parseInt(bet_amount) - 5));
                          }
                        }} //{bet_amountx == null ? () => dispatch(set_bet_amount(parseInt(bet_amount) - 5)) : ()=>{console.log(bet_amountx)}}
                        edge="end"
                      >
                        <Iconify style={{ width: '32px', height: '32px' }} icon="ci:minus-square" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start" sx={{ padding: '0px' }}>
                      <IconButton
                        sx={{ color: 'rgb(9, 14, 44)', padding: '0px' }}
                        onClick={() => dispatch(set_bet_amount(parseInt(bet_amount) + 5))} //{() => dispatch(set_bet_amount((bet_amountx && parseInt(bet_amountx) > 0) ? parseInt(bet_amountx) + 5 : parseInt(bet_amount) + 5))}
                        edge="end"
                      >
                        <Iconify style={{ width: '32px', height: '32px' }} icon="material-symbols:add-box" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{
                  input: { textAlign: 'center', fontWeight: 'bolder', fontSize: '24px', boxSizing: 'content-box' },
                  label: { color: 'rgb(9, 14, 44)' },
                }}
                style={{ display: logged_in_user.is_fixed_winning ? 'block' : 'none' }}
                error=""
                name="winning"
                label="Total Winning"
                type="number"
                value={fixed_winning}
                onChange={(e) => dispatch(set_fixed_amount(e.target.value))}
              />

              {show_number_of_players && (
                <TextField
                  sx={{
                    input: { textAlign: 'center', fontWeight: 'bolder', fontSize: '24px', boxSizing: 'content-box' },
                    label: { color: 'rgb(9, 14, 44)' },
                  }}
                  error=""
                  name="winning"
                  value={selectedBoards?.length > 0 ? selectedBoards?.length / 2 + '' : ''}
                  type="text"
                />
              )}

              <TextField
                sx={{
                  input: { textAlign: 'center', fontWeight: 'bolder', fontSize: '24px', boxSizing: 'content-box' },
                  label: { color: 'rgb(9, 14, 44)' },
                }}
                error=""
                name="autoAdd"
                label="Add Cartela"
                //type="number"
                value={board_number}
                onKeyDown={_handleCartelaKeyDown}
                onChange={(e) => set_board_number(Number(e.target.value))}
                InputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            </Stack>

            <Button autoFocus color="inherit" onClick={handleSave}>
              Save
            </Button>
            <Button autoFocus color="inherit" onClick={handleClear}>
              Clear
            </Button>
          </Toolbar>
        </AppBar>
        <Stack direction={'column'}>
          <CardSelector />
        </Stack>
      </Dialog>
    </>
  );
}

export default CartelaSelect