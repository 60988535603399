import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {  Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, Typography, Box, IconButton } from '@mui/material';
import { Slide } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';

import {
  OutlinedFlag,
  PhoneAndroid,
  Dashboard,
  Paid,
  Circle
} from '@mui/icons-material';

import { cartelaPurchase } from 'src/redux/slices/jackpot/jackpotGameplaySlice';
import CustomizedSnackbars from 'src/components/bingo/subcomponents/SnackBar';

import {getCreditByPhoneAndGame} from 'src/redux/slices/jackpot/jackpotGameCreditSlice';

//react window is for rendering large lists of items efficiently
import {FixedSizeGrid} from 'react-window';
import { useTheme, useMediaQuery } from '@mui/material';

// Dialog Slide Transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const BoardItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'is_reserved',
})(({ theme, is_reserved }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: is_reserved ? theme.palette.grey[300] : theme.palette.background.paper,
  cursor: is_reserved ? 'not-allowed' : 'pointer', // Set non-selectable style
  pointerEvents: is_reserved ? 'none' : 'auto', // Prevent click events when reserved
  '&:hover': {
    backgroundColor: is_reserved ? theme.palette.grey[300] : theme.palette.action.hover,
  },
}));



// Bingo Grid Style
const BingoGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const ITEMS_PER_ROW = {
  xs:2,
  sm:4,
  md:12
};
const ITEM_SIZE = 20;
const GRID_WIDTH = window.innerWidth - 40;

function CartelaGrid({ boardIds, handleCartelaSelection }) {
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

   // Calculate viewport height minus header
   const [gridHeight, setGridHeight] = useState(window.innerHeight - 48);
   
   useEffect(() => {
     const updateHeight = () => {
       setGridHeight(window.innerHeight - 48);
     };
     
     window.addEventListener('resize', updateHeight);
     return () => window.removeEventListener('resize', updateHeight);
   }, []);




  const ITEM_HEIGHT = 60;
  const GRID_WIDTH = 950; // Set a default width

  // Determine columns based on screen size
  const columnCount = isMobile ? 2 : isTablet ? 4 : 10;
  const columnWidth = Math.floor(GRID_WIDTH / columnCount);
  const rowCount = Math.ceil(boardIds.length / columnCount);

  return (
    <div style={{ width: '100%', height: 'calc(100vh - 48px)'  }}>
      <FixedSizeGrid
        columnCount={columnCount}
        columnWidth={isMobile ? 100 : columnWidth}
        height={gridHeight}
        rowCount={rowCount}
        rowHeight={ITEM_HEIGHT}
        width={GRID_WIDTH}
      >
        {({ columnIndex, rowIndex, style }) => {
          const itemIndex = rowIndex * columnCount + columnIndex;
          const id = boardIds[itemIndex].board_id;
          const isReserved = boardIds[itemIndex].is_reserved;
          if (!id) return null;
          
          return (
            <div
              style={{
                ...style,
                padding: '4px',
              }}
            >
              <BoardItem is_reserved={isReserved} onClick={() => handleCartelaSelection(id)}>
                <Typography variant="subtitle1">{id}</Typography>
              </BoardItem>
            </div>
          );
        }}
      </FixedSizeGrid>
    </div>
  );
}

// Color scheme for BINGO columns
const columnColors = ['#FF5733', '#33FF57', '#3375FF', '#FF33A8', '#FFD633'];

const BuyCartelaComponent = ({ open, game, handleClose, _cartelas, _onCartelaSelect }) => {
  const dispatch = useDispatch();

  const _logged_in_user = useSelector((state) => state.auth.user);
  const { __purchase_error, __purchase_loading, __purchase_game_id, __users_credit, __user_credit_message } =
    useSelector((state) => state.jackpotGame);
  
    const __gameCredit = useSelector((state) => state.jackpotGameCredit.gameCredit);
  const [thisUsersCredit,setThisUsersCredit] = useState(null);
  const [boughtAmount,setBoughtAmount] = useState(0);
  const [canBuy,setCanBuy] = useState(0);

  useEffect(() => {
    if (game && _logged_in_user) {
      const amount = game.playerBoards?.filter(board => board.ownerPhone === _logged_in_user?.phone).length || 0;
      setBoughtAmount(amount);
    }
  }, [game, _logged_in_user]);

  useEffect(() => {
    if (game && _logged_in_user) {
      if(game?.betAmount > 0)
        setCanBuy(Math.floor(thisUsersCredit?.credit/game.betAmount));
    }
  }, [thisUsersCredit]);

  //const boughtAmount = game?.playerBoards?.filter(board => board.ownerPhone === _logged_in_user?.phone).length || 0;

  useEffect(() => {
    if (__gameCredit) {
      setThisUsersCredit(__gameCredit);
    }
  }, [__gameCredit]);

  // useEffect(() => {
  //   if (__users_credit) {
  //     setThisUsersCredit(__users_credit);
  //   }
  // }, [__users_credit]);

  const [selectedBoardId, setSelectedBoardId] = useState();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [boardNumbers, setBoardNumbers] = useState([]);

  const [phoneError, setPhoneError] = useState(null);
  const [boardError, setboardError] = useState(null);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  //fetch game credit by phone and game id

  useEffect(() => {
    const fetchCredit = async () => {
      if (_logged_in_user?.phone) {
        console.log()
        await dispatch(getCreditByPhoneAndGame({ phone: _logged_in_user?.phone, gameId: game.gameId }));
      }
    };
    fetchCredit();
  }, [open]);
  

  // Dummy board IDs
  //const boardIds = Array.from({ length: 1000 }, (_, i) => `${i + 1}`);
  //const boardIds = _cartelas?.filter((t) => !t.is_reserved).map((t) => t.board_id);
  //const boardIds = _cartelas?.map((t) => {t.board_id,t.is_reserved});

  const boardIds = _cartelas?.map((t) => ({
    board_id: t.board_id,
    is_reserved: t.is_reserved,
  }));
  

  const handleCartelaSelection = (board_id) => {
    setSelectedBoardId(board_id); // Set the selected board ID
    const selectedBoard = _cartelas.find((cartela) => cartela.board_id === board_id);

    if (selectedBoard) {
      setBoardNumbers(selectedBoard.board_numbers); // Update boardNumbers based on the selected board
    }
    _onCartelaSelect(board_id); // Call the parent selection handler
  };

  const handleBuyCartela = async () => {
    //user object
    //buyer phone
    //board id
    //dispatch
    try {
      setboardError(null);
      setPhoneError(null);

      if (!selectedBoardId) {
        setboardError('Please Select Cartela!');
        return;
      }
      if (!phoneNumber) {
        setPhoneError('Please Enter Phone Number!');
        return;
      }

      let input = phoneNumber.replace(/\D/g, '');
      if (input.length != 10) {
        setPhoneError('Invalid Phone Number');
        return;
      }

      setConfirmDialogOpen(true);

    } catch (error) {
      console.log(error);
      setPhoneError(error?.message);
    }
  };
  //confirm buy cartela
  const handleConfirmPurchase = async () => {
    let ownerPhone = _logged_in_user?.phone;
    const requestObject = {
      gameId: game.gameId,
      phone: phoneNumber,
      boardId: selectedBoardId,
      ownerPhone: ownerPhone,
    };

    await dispatch(cartelaPurchase(requestObject));
    setConfirmDialogOpen(false);

    // Update the balance and bought amount
    if(thisUsersCredit?.credit && thisUsersCredit?.credit > 0){
      setThisUsersCredit((prevCredit) => ({
        ...prevCredit,
        credit: prevCredit ? prevCredit.credit - game.betAmount : 0,
      }));
      setBoughtAmount((prevAmount) => prevAmount + 1);
      setCanBuy((prevCanBuy) => prevCanBuy - 1);
    }
    

  };

  const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const date = new Date(timeString);
    return date.toLocaleString('en-US', options); // Adjust locale as needed
  };

  const renderGrid = () => {
    if (!boardNumbers || boardNumbers.length === 0) {
      return <div>No boards selected numbers available.</div>;
    }

    return (
      <Grid container spacing={1}>
        {['B', 'I', 'N', 'G', 'O'].map((letter, colIdx) => (
          <Grid item xs={2.4} key={letter}>
            <Box
              sx={{
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: columnColors[colIdx],
                fontSize: '1.5rem',
                fontWeight: 'bold',
              }}
            >
              {letter}
            </Box>
            {boardNumbers[colIdx].map((num, i) => (
              <Box
                key={num}
                sx={{
                  height: '50px',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '5px',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                {num}
              </Box>
            ))}
          </Grid>
        ))}
      </Grid>
    );
  };

  const handlePhoneChange = (e) => {
    // Remove all non-digit characters
    let input = e.target.value.replace(/\D/g, '');

    // Limit input to 10 digits
    if (input.length > 10) {
      input = input.slice(0, 10);
    }

    setPhoneNumber(formatPhoneNumber(input));

    // Validate if the number is exactly 10 digits
    if (input.length === 10) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
  };

  const formatPhoneNumber = (input) => {
    const parts = input.match(/.{1,2}/g) || []; // Break input into groups of 2 digits
    return parts.join('-'); // Join groups with a dash
  };

  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} fullScreen>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          ካርቴላ ሽያጭ (
          {__user_credit_message ? (
            <span style={{ color: 'red' }}>{__user_credit_message}</span>
          ) : (
            // <span style={{ color: 'green' }}>{`💵 ባላንስ: ${__users_credit ? __users_credit.credit : 0} ብር`}</span>
            <span style={{ color: 'green' }}>{`💵 ባላንስ: ${thisUsersCredit ? thisUsersCredit.credit : 0} ብር`}</span>
          )}
          )
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* Left Section - Scrollable Grid of Boards */}
            <Grid item xs={12} sm={6} md={8} sx={{ height: '100vh', overflowY: 'auto' }}>
              <Typography variant="h6" gutterBottom>
                ካርቴላ ይምረጡ
              </Typography>
              <Grid container spacing={1}>
                {/* {boardIds.map((id) => (
                <Grid item xs={6} md={1} sm={3} key={id}>
                  <BoardItem onClick={() => handleCartelaSelection(id)}>
                    <Typography variant="subtitle1">{id}</Typography>
                  </BoardItem>
                </Grid>
              ))}  */}
                <CartelaGrid boardIds={boardIds} handleCartelaSelection={handleCartelaSelection} />
              </Grid>
            </Grid>

            {/* Right Section */}
            <Grid item xs={12} sm={6} md={4}>
           
<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, color: '#2e7d32', fontWeight: 'bold', mb: 2 }}>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <span role="img" aria-label="trophy">🏆</span>
    <span>{`ደራሽ: ${game?.totalWinning}`}</span>
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <span role="img" aria-label="money bag">💰</span>
    <span>{`መደብ: ${game?.betAmount}`}</span>
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <span role="img" aria-label="clock">🕒</span>
    <span>{`ሰአት: ${formatTime(game?.startDateTime)}`}</span>
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <span role="img" aria-label="card">🃏</span>
    <span>{`መግዛት የሚችሉት ካርድ መጠን: ${isNaN(canBuy) ? 0 : canBuy}`}</span>
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <span role="img" aria-label="shopping cart">🛒</span>
    <span>{`የገዙት: ${boughtAmount}`}</span>
  </Box>
</Box>

              <Box mt={3}>
                <TextField
                  error={phoneError}
                  helperText={phoneError ? 'Phone number must be 10 digits' : ''}
                  label="Phone Number"
                  fullWidth
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  margin="normal"
                  variant="outlined"
                />

                <TextField
                  error={boardError}
                  helperText={boardError ?? ''}
                  label=""
                  fullWidth
                  value={selectedBoardId}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              {/* Actions (Purchase and Cancel Buttons) */}
              <DialogActions>

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={__purchase_loading ? <CircularProgress size={20} color="inherit" /> : <ShoppingCartIcon />}
                  onClick={handleBuyCartela}
                  disabled={!thisUsersCredit?.credit || thisUsersCredit?.credit <= 0}
                >
                  Purchase
                </Button>
                <Button variant="outlined" color="secondary" startIcon={<CancelIcon />} onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
              {__purchase_error && (
                <span style={{ color: 'red', fontSize: '11px', fontWeight: 'bold' }}>{__purchase_error}</span>
              )}
              {!__purchase_error && __purchase_game_id === game.gameId && (
                <span style={{ color: 'green', fontSize: '11px', fontWeight: 'bold' }}>purchase successful</span>
              )}
              <BingoGrid container>{renderGrid()}</BingoGrid>

              {(__purchase_error || __purchase_game_id === game.gameId) && (
                <CustomizedSnackbars
                  snackOpen={Boolean(__purchase_error || __purchase_game_id === game.gameId)}
                  snackMessage={__purchase_error || 'Purchase successful!'}
                  handleSnackClose={() => {
                    dispatch({ type: 'jackpotGame/clearPurchaseStatus' });
                  }}
                  is_saved={__purchase_game_id === game.gameId}
                  is_failed={Boolean(__purchase_error)}
                  is_loading={false}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog */}

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            minWidth: { xs: '90%', sm: 400 },
            maxWidth: 480,
            boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
          },
        }}
      >
        <DialogTitle
          sx={{
            pb: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <ShoppingCartIcon sx={{ color: 'primary.main' }} />
          <Typography variant="h6" component="span" sx={{ fontWeight: 600 }}>
            Confirm Purchase
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ pt: 3, pb: 3 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 3,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                fontSize: '0.95rem',
              }}
            ></Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: 'grey.50',
              p: 2.5,
              borderRadius: 1.5,
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <PhoneAndroid sx={{ color: 'text.secondary', fontSize: 20 }} />
                    <Typography sx={{ color: 'text.secondary', fontSize: '0.95rem' }}>Phone Number</Typography>
                  </Box>
                  <Typography sx={{ fontWeight: 500 }}>{phoneNumber}</Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <Dashboard sx={{ color: 'text.secondary', fontSize: 20 }} />
                    <Typography sx={{ color: 'text.secondary', fontSize: '0.95rem' }}>Board ID</Typography>
                  </Box>
                  <Typography sx={{ fontWeight: 500 }}>{selectedBoardId}</Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pt: 1,
                    borderTop: '1px dashed',
                    borderColor: 'divider',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <Paid sx={{ color: 'primary.main', fontSize: 22 }} />
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: 'text.primary',
                      }}
                    >
                      Total Amount
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '1.1rem',
                      color: 'primary.main',
                    }}
                  >
                    {game?.betAmount} ETB
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            px: 3,
            py: 2,
            borderTop: '1px solid',
            borderColor: 'divider',
            gap: 1.5,
          }}
        >
          <Button
            onClick={() => setConfirmDialogOpen(false)}
            color="inherit"
            startIcon={<CloseIcon />}
            sx={{
              fontWeight: 500,
              textTransform: 'none',
              px: 2,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmPurchase}
            variant="contained"
            disabled={__purchase_loading}
            startIcon={!__purchase_loading && <Circle />}
            sx={{
              px: 3,
              py: 1,
              textTransform: 'none',
              fontWeight: 500,
              borderRadius: 1,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            {__purchase_loading ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} sx={{ color: 'white' }} />
                <span>Processing...</span>
              </Box>
            ) : (
              'Confirm Purchase'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BuyCartelaComponent;
