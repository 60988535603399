import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

import jackpotCreditService from "src/services/jackpot/jackpotCreditService";


const initalStates = {
    __jackpot_credit_obj : null,
    __jackpot_credit_trxn_history: [],
    __jackpot_credit_loading:null,
    __jackpot_credit_error:null,
    __jackpot_credit_message:null,

    __jackpot_admin_bonus_data:null,
    __jackpot_admin_bonus_msg:null,
};

export const distributeAdminBonus = createAsyncThunk(
  'jackpotCredit/distributeAdminBonus',
  async (gameId, { rejectWithValue }) => {
    try {
      var response = await jackpotCreditService.distributeAdminBonus(gameId);
      //console.log(response);
      return response;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const calculateCreditDistribution = createAsyncThunk(
  'jackpotCredit/calculateCreditDistribution',
  async (gameId, { rejectWithValue }) => {
    try {
      var response = await jackpotCreditService.calculateCreditDistribution(gameId);
      //console.log(response);
      return response;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const createOrUpdateCredit = createAsyncThunk(
  'jackpotCredit/createOrUpdateCredit',
  async (creditData, { rejectWithValue }) => {
    try {
      var response = await jackpotCreditService.createOrUpdateCredit(creditData);
      //console.log(response);
      return response;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const getJackpotCreditByPhone = createAsyncThunk(
    'jackpotCredit/getJackpotCreditByPhone',
    async (phone, { rejectWithValue }) => {
      try {
        var response = await jackpotCreditService.getCreditByPhone(phone);
        //console.log(response);
        return response;
      } catch (error) {
        //console.log(error);
        return rejectWithValue(error?.response?.data);
      }
    }
);

  export const getJackpotCreditHistory = createAsyncThunk(
    'jackpotCredit/getJackpotCreditHistory',
    async (phone, { rejectWithValue }) => {
      try {
        var response = await jackpotCreditService.getCreditHistoryByPhone(phone);
        //console.log(response);
        return response;
      } catch (error) {
        //console.log(error);
        return rejectWithValue(error?.response?.data);
      }
    }
  );

  export const getCreditDetailForUser = createAsyncThunk(
    'jackpotCredit/getCreditDetailForUser',
    async (data, { rejectWithValue }) => {
      try {
        
        var response = await jackpotCreditService.getCreditDetailForUser(data.start,data.end);
        //console.log(response);
        return response;
      } catch (error) {
        //console.log(error);
        return rejectWithValue(error?.message);
      }
    }
  );

  export const fetchUserCreditData = createAsyncThunk(
    'jackpotCredit/fetchUserCreditData',
    async (_, { rejectWithValue }) => {
      try {
        var response = await jackpotCreditService.getUserCreditData();
        //console.log(response);
        return response.data;
      } catch (error) {
        //console.log(error);
        return rejectWithValue(error?.response?.data);
      }
    }
  );

export const getTransactionHistoryById = createAsyncThunk(
  'jackpotCredit/getTransactionHistoryById',
  async (transactionId, { rejectWithValue }) => {
    try {
      var response = await jackpotCreditService.getTransactionHistoryById(transactionId);
      return response;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const reverseTransaction = createAsyncThunk(
  'jackpotCredit/reverseTransaction',
  async (transactionId, { rejectWithValue }) => {
    try {
      var response = await jackpotCreditService.reverseTransaction(transactionId);
      return response;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error);
    }
  }
);

const jackpotCreditSlice = createSlice({
  name: 'jackpotCredit',
  initialState: {
    __jackpot_credit_list: [], // Stores user + jackpot credit data for grid
    __jackpot_credit_loading: false,
    __jackpot_credit_error: null,
    __jackpot_credit_message: '',

    __distributionData:null,
    __credit_distribution_loading:null,
    __credit_distribution_message:null,

    __credit_detail_data:null,
    __credit_detail_loading:null,
    __credit_detail_error:null,

    __transaction_history_data:null,
    __transaction_history_loading:null,
    __transaction_history_error:null,

    __reverse_transaction_data:null,
    __reverse_transaction_loading:null,
    __reverse_transaction_error:null
  },
  reducers: {
    resetJackpotCreditError: (state) => {
      state.__jackpot_credit_error = null;
      state.__jackpot_credit_message = null;
      
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrUpdateCredit.pending, (state) => {
        state.__jackpot_credit_loading = true;
        state.__jackpot_credit_error = 'Updating credit...';
      })
      .addCase(createOrUpdateCredit.fulfilled, (state, action) => {
        state.__jackpot_credit_loading = false;
        state.__jackpot_credit_error = 'Credit updated successfully';

        // Format the returned credit data
        let data = action.payload?.data?.receiverCredit;
        //console.log(data);
        const { phone, topupAmount, previousCredit, credit } = data || {};
        const updatedCredit = {
          phone,
          topup: topupAmount,
          previous: previousCredit,
          current: credit
        };

        // Find and update entry in __jackpot_credit_list if it exists
        const index = state.__jackpot_credit_list.findIndex((item) => item.phone === phone);
        if (index !== -1) {
          state.__jackpot_credit_list[index] = { ...state.__jackpot_credit_list[index], ...updatedCredit };
        } else {
          state.__jackpot_credit_list.push(updatedCredit);
        }
      })
      .addCase(createOrUpdateCredit.rejected, (state, action) => {
        state.__jackpot_credit_loading = false;
        state.__jackpot_credit_error = action.payload.message;
        state.__jackpot_credit_message = 'Failed to update credit';
      })

      .addCase(fetchUserCreditData.pending, (state) => {
        state.__jackpot_credit_loading = true;
        state.__jackpot_credit_message = 'Fetching user credit data...';
      })
      .addCase(fetchUserCreditData.fulfilled, (state, action) => {
        //console.log(action.payload);
        state.__jackpot_credit_list = action.payload?.map((credit) => ({
          phone: credit.phone,
          role: credit.role,
          name: credit.name,
          topup: credit.topup,
          previous: credit.previous,
          current: credit.current
        }));
        state.__jackpot_credit_loading = false;
        state.__jackpot_credit_message = 'User credit data fetched successfully';
        //console.log(state.__jackpot_credit_list);
      })
      .addCase(fetchUserCreditData.rejected, (state, action) => {
        state.__jackpot_credit_loading = false;
        state.__jackpot_credit_error = action.payload;
        state.__jackpot_credit_message = 'Failed to fetch user credit data';
      })

      .addCase(calculateCreditDistribution.pending,(state,action)=>{
        state.__credit_distribution_message = null;
        state.__credit_distribution_loading = true;
        state.__distributionData = null;
      })
      .addCase(calculateCreditDistribution.fulfilled,(state,action)=>{
        //console.log(action.payload);
        state.__credit_distribution_message = null;
        state.__credit_distribution_loading = null;
        state.__distributionData = action.payload?.data;
      })
      .addCase(calculateCreditDistribution.rejected,(state,action)=>{
        //console.log(action.payload.message);
        state.__credit_distribution_message = action.payload?.message;
        state.__credit_distribution_loading = null;
        state.__distributionData = null;
      })
      //distributeAdminBonus
      .addCase(distributeAdminBonus.pending,(state,action)=>{
        state.__jackpot_admin_bonus_msg = "processing ...";
        state.__jackpot_admin_bonus_data = null;
      })
      .addCase(distributeAdminBonus.fulfilled,(state,action)=>{
        
        state.__jackpot_admin_bonus_msg = null;
        state.__jackpot_admin_bonus_data = action.payload?.data;
      })
      .addCase(distributeAdminBonus.rejected,(state,action)=>{
        state.__jackpot_admin_bonus_msg = action.payload.message;
        state.__jackpot_admin_bonus_data = null;
      })
      .addCase(getCreditDetailForUser.pending,(state,action)=>{
        state.__credit_detail_loading = 'loading ...';
        state.__credit_detail_error=null; 
      })
      .addCase(getCreditDetailForUser.fulfilled,(state,action)=>{
        //console.log(action.payload.data);
        state.__credit_detail_loading = null;
        state.__credit_detail_error=null;
        state.__credit_detail_data = action.payload.data;
      })
      .addCase(getCreditDetailForUser.rejected,(state,action)=>{
        //console.log(action.payload);
        state.__credit_detail_loading = null;
        state.__credit_detail_error=action.payload?.message;
        state.__credit_detail_data = null;
      })
      .addCase(getTransactionHistoryById.pending,(state,action)=>{
        state.__transaction_history_loading = 'loading ...';
        state.__transaction_history_error=null; 
      })  
      .addCase(getTransactionHistoryById.fulfilled,(state,action)=>{
        //console.log(action.payload);
        state.__transaction_history_loading = null;
        state.__transaction_history_error=null;
        state.__transaction_history_data = action.payload?.data;
      })  
      .addCase(getTransactionHistoryById.rejected,(state,action)=>{
        //console.log(action.payload);
        state.__transaction_history_loading = null;
        state.__transaction_history_error=action.payload?.message;
        state.__transaction_history_data = null;
      })
      .addCase(reverseTransaction.pending,(state,action)=>{
        state.__transaction_history_loading = 'loading ...';
        state.__transaction_history_error=null; 
      })  
      .addCase(reverseTransaction.fulfilled,(state,action)=>{
        //console.log(action.payload);
        state.__transaction_history_loading = null;
        state.__transaction_history_error=null;
        state.__reverse_transaction_data = action.payload?.data;
      })    
      .addCase(reverseTransaction.rejected,(state,action)=>{
        //console.log(action.payload);
        state.__transaction_history_loading = null;
        state.__transaction_history_error=action.payload?.message || action.payload;
        state.__reverse_transaction_data = null;
      })
  }
});

export const { resetJackpotCreditError } = jackpotCreditSlice.actions;
export default jackpotCreditSlice.reducer;