import React,{useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from "@mui/material";
import { distributeAdminBonus } from "src/redux/slices/jackpot/jackpotCreditSlice";

const AdminBonusDialog = ({ open, onClose, onSave, game }) => {

  const  __jackpot_admin_bonus_msg  = useSelector(
    (state) => state.jackpotCredit.__jackpot_admin_bonus_msg
  );

  const distObjs  = useSelector(
    (state) => state.jackpotCredit.__jackpot_admin_bonus_data
  );

  const {  __credit_distribution_error, __credit_distribution_status } = useSelector(
    (state) => state.jackpotCreditDistribution
  );

  

  const dispatch = useDispatch();
  useEffect(() => {
    if (open) {
      dispatch(distributeAdminBonus(game?.gameId));
    }
  }, [open, game?.gameId, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen>
      <DialogTitle>
        Admin Bonus Distribution For -{' '}
        <span style={{ color: 'teal', fontSize: '1rem', fontWeight: 'bolder' }}>{game?.gameName}</span>
      </DialogTitle>
      <DialogContent>
        {/* Summary Cards */}

        <Grid container spacing={3} justifyContent="space-between">
          {[
            { label: 'Bet Amount', value: game?.betAmount, color: '#9C27B0' },
            { label: 'Total Players', value: distObjs?.[0]?.totalPlayers, color: '#FF9800' },
            {
              label: 'Total Distributable',
              value: distObjs?.reduce((sum, obj) => sum + obj.amount, 0),
              color: '#E91E63',
            },
          ].map((item, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  textAlign: 'center',
                  height: '100%',
                  borderRadius: 2,
                  background: '#ffffff',
                  border: '1px solid #e0e0e0',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    borderColor: item.color,
                  },
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 600,
                    fontSize: '0.875rem',
                    mb: 1,
                    textTransform: 'uppercase',
                    letterSpacing: '0.5px',
                  }}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: 'text.primary',
                    fontWeight: 700,
                    fontSize: '1.25rem',
                  }}
                >
                  {typeof item.value === 'number'
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(item.value)
                    : item.value || 'N/A'}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Distribution Details Table */}

        {__jackpot_admin_bonus_msg && (
          <span style={{ fontSize: '12px', fontWeight: 'bolder', color: 'bisque' }}>{__jackpot_admin_bonus_msg}</span>
        )}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Phone</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Game ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Branch</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Player Count</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Is Bonus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {distObjs?.map((distObj, index) => (
                <TableRow key={index}>
                  <TableCell>{distObj.phone}</TableCell>
                  <TableCell>{distObj.username}</TableCell>
                  <TableCell>{distObj.role}</TableCell>
                  <TableCell>{distObj.game_id}</TableCell>
                  <TableCell style={{ background: 'teal', color: 'white', fontWeight: 'bolder' }}>
                    {distObj.amount}
                  </TableCell>
                  <TableCell>{distObj.fromBranch}</TableCell>
                  <TableCell>{new Date(distObj.date).toLocaleString()}</TableCell>
                  <TableCell>{distObj.playerCount}</TableCell>
                  <TableCell>{distObj.owner}</TableCell>
                  <TableCell>{distObj.isBonus ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions>
        <DialogActions>
          <Stack spacing={2} width="100%">
            <div>
              <Button onClick={onClose} color="secondary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={onSave} color="primary" variant="contained" sx={{ ml: 1 }}>
                Save
              </Button>
            </div>
            <div>
              {__credit_distribution_status && (
                <Typography color="primary" fontSize="11px">
                  {__credit_distribution_status}
                </Typography>
              )}
              {__credit_distribution_error && (
                <Typography color="error" fontSize="11px">
                  {__credit_distribution_error}
                </Typography>
              )}
            </div>
          </Stack>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default AdminBonusDialog;
