import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
} from "@mui/material";



import { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { getGameCreditsForUser,createOrUpdateGameCredit ,resetCreditStatus} from "src/redux/slices/jackpot/jackpotGameCreditSlice";
import _ from "lodash";

const formatCurrency = (amount) => `$${amount ? amount.toFixed(2) : 0}`;

const GameCreditModal = ({open,phone,closeDialog}) => {

  const dispatch = useDispatch();
  const stateGameData = useSelector((state) => state.jackpotGameCredit.userGameCredits);
  const error = useSelector((state) => state.jackpotGameCredit.error);
  const saveMsg = useSelector((state) => state.jackpotGameCredit.saveMsg);
  const { __users_credit } = useSelector((state) => state.jackpotGame);

  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    //Fetch game credits for user
    const fetchGameCredits = async () => {
      try {
        await dispatch(getGameCreditsForUser(phone));
      } catch (error) {
        console.error(error);
      }
    };
    fetchGameCredits();
  }, [open]);

  useEffect(()=>{
    if(saveMsg && saveMsg.length > 0){
      setGameData((prevData) =>
        prevData.map((game) => ({
          ...game,
          selected_package: null,
          amount: 0,
        }))
      );
    }
  },[saveMsg])

  useEffect(()=>{
    setGameData(stateGameData);
    
  },[stateGameData]);

  useEffect(()=>{ 
    setLocalError('');
  },[open])

  const handlePackageSelect = (gameId, selectedKey) => {
    const selectedValue = gameData?.find((game) => game.game_id === gameId)?.packages[selectedKey];
    if (selectedValue) {
      setGameData((prevData) =>
        prevData.map((game) =>
          game.game_id === gameId
            ? { ...game, selected_package: selectedKey, amount: selectedValue,remaining:game.remaining - game.amount + selectedValue }
            : game
        )
      );
    }
  };

  const [localError,setLocalError] = useState('');

  const saveGameCredits = async () => {
    try {
      setLocalError('');
      dispatch(resetCreditStatus());
      const dataToSave = gameData?.filter((game) => game.amount > 0);
      if (dataToSave.length > 0) {
        const currentCredit = __users_credit?.credit;
        if (currentCredit && currentCredit >= _.sumBy(dataToSave, 'selected_package')) {
          //saving logic
          const saveData = dataToSave.map((game) => {
            return {
              phone,
              game_id: game.game_id,
              package: game.selected_package,
              amount: game.amount,
              type: 'topup',
              remark: 'Game credit topup',
              sender: phone,
              receiver: phone,
            };
          });

          await dispatch(createOrUpdateGameCredit(saveData));
        } else {
          setLocalError('Insufficient credit to save the selected packages!');
        }
      } else {
        setLocalError('Please select at least one package to save!');
      }
    } catch (error) {
      setLocalError('Error saving game credits!');
    }
  };

  return (
    <>
      <Modal open={open} onClose={closeDialog}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            Game Credit Details - {`Credit (${__users_credit ? formatCurrency(__users_credit ? __users_credit.credit : 0) : 0} birr)`}
          </Typography>
          {error && <span style={{color:'red',fontSize:'11px',fontWeight:'bolder'}}>{error}</span>}
          {localError && <span style={{color:'red',fontSize:'11px',fontWeight:'bolder'}}>{localError}</span>}
          {saveMsg && <span style={{color:'green',fontSize:'11px',fontWeight:'bolder'}}>{saveMsg}</span>}

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Game Name</TableCell>
                  <TableCell>Bet Amount</TableCell>
                  <TableCell>Total Players</TableCell>
                  <TableCell>Used Amount</TableCell>

                  <TableCell>Package</TableCell>
                  <TableCell>Selected Amount</TableCell>

                  <TableCell>Remaining</TableCell>
                  <TableCell>User Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gameData?.map((game) => (
                  <TableRow key={game.game_id}>
                    <TableCell>{game.game_name}</TableCell>
                    <TableCell>{game.bet_amount}</TableCell>
                    <TableCell>{game.total_players}</TableCell>
                    <TableCell>{game.used_amount}</TableCell>

                    <TableCell>
                      <Select
                        value={game.selected_package || ''}
                        onChange={(e) => handlePackageSelect(game.game_id, e.target.value)}
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value="" disabled>
                          Select Package
                        </MenuItem>
                        {Object.entries(game.packages).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {key} (Amount: {value})
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>{game.amount}</TableCell>
                    <TableCell>{game.remaining}</TableCell>
                    <TableCell>{game.user_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} textAlign="right">
            <Button
              variant="outlined"
              onClick={closeDialog}
              sx={{ marginRight: 2 }} // Add spacing between buttons
            >
              Close
            </Button>
            <Button variant="outlined" onClick={saveGameCredits}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GameCreditModal;
