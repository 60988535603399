
import { configureStore,combineReducers } from "@reduxjs/toolkit";
import authReducer from './slices/userSlice';
import boardReducer from './slices/boardSlice';
import  appStateReducer  from "./slices/appStateSlice";
import transactionReducer from './slices/gamePlaySlice';
import transactionListReducer from './slices/transactionSlice';
import companyReducer from './slices/companySlice';
import creditReducer from './slices/creditSlice';
//jackpot

import jackpotReducer from './slices/jackpot/jackpotGameplaySlice';
import jackpotPatternReducer from './slices/jackpot/jackpotPatternSlice';
import jackpotUserReducer from './slices/jackpot/jackpotUserSlice';
import jackpotCreditReducer from './slices/jackpot/jackpotCreditSlice';
import jackpotCreditDistributionSliceReducer from './slices/jackpot/jackpotCreditDistributionSlice';
import jackpotDashboardReducer from './slices/jackpot/jackpotDashboardSlice';
import jackpotGameCreditReducer from './slices/jackpot/jackpotGameCreditSlice';

const reducer = combineReducers({
    auth:authReducer,
    appState:appStateReducer,
    transactionState:transactionReducer,
    transactionList:transactionListReducer,
    companies:companyReducer,
    boards:boardReducer,
    credits:creditReducer,
    jackpotGame:jackpotReducer,
    jackpotPattern:jackpotPatternReducer,
    jackpotUser:jackpotUserReducer,
    jackpotCredit:jackpotCreditReducer,
    jackpotCreditDistribution:jackpotCreditDistributionSliceReducer,
    jackpotDashboardData:jackpotDashboardReducer,
    jackpotGameCredit:jackpotGameCreditReducer
});

const reducerProxy = (state,action)=>{
  if(action.type === 'auth/logout'){
    localStorage.removeItem('bingo_user_info');
    localStorage.removeItem('bingo_access_token');
    return reducer(undefined,action);
  }
  return reducer(state,action);
}

export const store = configureStore({
    reducer:reducerProxy,//reducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

//add root reducer here