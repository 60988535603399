import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme} from '@mui/material/styles';
import {InputAdornment,IconButton,MenuItem} from '@mui/material';

import { Grid, Container, Typography,  Stack,TextField,Button } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_dashboard_data, get_dashboard_data_by_branch,get_dashboard_data_per_agent_subagent,getTransactionDetailPerCompanyPerMonth } from 'src/redux/slices/transactionSlice';
import { getAgentUsers, get_user_info } from 'src/redux/slices/userSlice';
import CustomBinTable from './DashboardTable';
import { Clear, Print, Search } from '@mui/icons-material';

// ----------------------------------------------------------------------

const current_date = () => {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  return date;
};

export default function DashboardAppPage() {
  const theme = useTheme();
  const dashboardData = useSelector(state=>state.transactionList.dashboard_data);
  const dashboard_data_per_branch = useSelector(state=>state.transactionList.dashboard_data_per_branch);
  const attendance_is_loading = useSelector(state=>state.transactionList.attendanceLoading);

  //active company status states
  const company_active_status = useSelector(state=>state.companies.company_active_status);
  const company_active_status_loading = useSelector(state=>state.companies.company_active_status_loading);
  const company_active_status_error = useSelector(state=>state.companies.company_active_status_error);

  //const dashboard_data_per_company = useSelector(state=>state.transactionList.dashboard_data_per_company);
  
  const current_company = useSelector(state=>state.auth.current_company);
  const user = useSelector((state) => state.auth.user);

  const [expiryBannerVisible,setExpiryBannerVisible] = useState(false);
  const [remainginDays,setRemainingDays] = useState(null);

  const [startDate,setStartDate] = useState(current_date());
  const [endDate,setEndDate] = useState(current_date());
  const [isSuper,setIsSuper] = useState(user?.role === 'super' || user?.role==='root' || user?.role==='Agent' || user?.role==='SubAgent');

  const [agent,setAgent] = useState(null);
  const [subAgent,setSubAgent] = useState(null);

  const listOfAgents = useSelector(state=> state.auth.agent_lists);

  const dispatch = useDispatch();



  useEffect(()=>{
    if (current_company) {
      if (current_company.expiry_date) {
        var expiryDateRaw = current_company.expiry_date;
        var currentDateRaw = new Date();

        var expiryDate = new Date(expiryDateRaw).toISOString().split('T')[0];
        var currentDate = new Date(currentDateRaw).toISOString().split('T')[0];

        var msPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds per day

        var remainingD = Math.ceil((new Date(expiryDate) - new Date(currentDate)) / msPerDay);
        if(remainingD <= 5){
          setRemainingDays(remainingD);
          setExpiryBannerVisible(true);
        }else{
          setExpiryBannerVisible(false);
        }
        
      }
    }
  });

  useEffect(() => {
    // Check if listOfAgents is empty or undefined
    //if (!listOfAgents || listOfAgents.length === 0) {
      dispatch(getAgentUsers());
    //}
     
  }, []);

  useEffect(()=>{
    const fetch_dash_board_data = async () => {

      await dispatch(get_dashboard_data());
      await dispatch(get_dashboard_data_by_branch());
      {isSuper && await dispatch(getTransactionDetailPerCompanyPerMonth({start:startDate,end:endDate}))}

    };
    fetch_dash_board_data();
  },[]);

  const handleFindClick = async () => {
    var dateObj = {start:startDate,end:endDate,agent:agent,subAgent:subAgent};
    await dispatch(getTransactionDetailPerCompanyPerMonth(dateObj));

    try {
      if ((agent && agent.length > 0) || (subAgent && subAgent.length > 0)) {
        let srch_role = null;
        let srch_usr_name = null;

        if (agent && agent.length > 0) {
          srch_role = 'Agent';
          srch_usr_name = agent;
        }
        if (subAgent && subAgent.length > 0) {
          srch_role = 'SubAgent';
          srch_usr_name = subAgent;
        }
        var data = { srch_role: srch_role, srch_usr_name: srch_usr_name };
        //console.log(data)
        await dispatch(get_dashboard_data_per_agent_subagent(data));
      }
    } catch (error) {
      console.log(error);
    }
    
  }

  const handleClearClick = () => {
    setStartDate(current_date());
    setEndDate(current_date());
    setSubAgent(null);
    setAgent(null);
  }

  const get_chart_data = () => {
    var chartData = [];
    dashboard_data_per_branch?.forEach(element => {
      const label = element._id ? element._id : '';
      chartData.push({
        label: label,
        value: element.totalAmount
      });
    });
    return chartData;
  };

  const createAgentList = (type) => {
    
    var agents = listOfAgents?.filter(t=>t.role === type)?.map((agent)=>{
          return <MenuItem key={agent.userName} value={agent.userName}>{agent.userName}</MenuItem>
    });
    agents.unshift(<MenuItem key="empty" value="">Clear</MenuItem>);
    return agents;
}

  return (
    <>
      <Helmet>
        <title> Dashboard | ሃገሬ ጌምስ </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back{' '}
          <span style={{ color: 'red' }}>
            {expiryBannerVisible && `(Your System Will Expire in ${remainginDays} days!)`}
          </span>
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Todays Sales"
              total={dashboardData ? dashboardData.totalAmountDay : 0}
              icon={'ant-design:dollar-circle'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Weekly Sales"
              total={dashboardData ? dashboardData.totalAmountWeek : 0}
              color="info"
              icon={'ant-design:euro-circle'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Monthly Sales"
              total={dashboardData ? dashboardData.totalAmountMonth : 0}
              color="warning"
              icon={'ant-design:pound-circle'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Year To Date"
              total={dashboardData ? dashboardData.totalAmountYear : 0}
              color="error"
              icon={'ant-design:bank'}
            />
          </Grid>

          {isSuper && (
            <>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  onChange={(e) => setStartDate(e.target.value)}
                  id="date"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  onChange={(e) => setEndDate(e.target.value)}
                  id="date"
                  label="End Date"
                  type="date"
                  value={endDate}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              {user.role === 'super' ? (
                <>
                  {/* Render both TextField components if the user's role is 'super' */}
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      labelId="agent"
                      select
                      value={agent}
                      label="Agent"
                      defaultValue=""
                      onChange={(e) => setAgent(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Iconify icon="mdi:face-agent" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ width: '100%' }}
                    >
                      {createAgentList('Agent')}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} flexGrow={1}>
                    <TextField
                      labelId="subAgent"
                      select
                      value={subAgent}
                      label="Sub Agent"
                      defaultValue=""
                      onChange={(e) => setSubAgent(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Iconify icon="material-symbols:support-agent" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ width: '100%' }}
                    >
                      {createAgentList('SubAgent')}
                    </TextField>
                  </Grid>
                </>
              ) : (
                user.role === 'Agent' && (
                  <Grid item xs={12} sm={6} md={2} flexGrow={1}>
                    <TextField
                      labelId="subAgent"
                      select
                      value={subAgent}
                      label="Sub Agent"
                      defaultValue=""
                      onChange={(e) => setSubAgent(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Iconify icon="material-symbols:support-agent" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ width: '100%' }}
                    >
                      {createAgentList('SubAgent')}
                    </TextField>
                  </Grid>
                )
              )}

              {/* {(user.role === 'super' || user.role === 'Agent') && (
                <>
                  <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    labelId="agent"
                    select
                    value={agent}
                    label="Agent"
                    defaultValue=""
                    onChange={(e) => setAgent(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Iconify icon="mdi:face-agent" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: '100%' }} 
                  >
                    {createAgentList('Agent')}
                  </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} flexGrow={1}>
                  <TextField
                    labelId="subAgent"
                    select
                    value={subAgent}
                    label="Sub Agent"
                    defaultValue=""
                    onChange={(e) => setSubAgent(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Iconify icon="material-symbols:support-agent" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: '100%' }} 
                  >
                    {createAgentList('SubAgent')}
                  </TextField>
                  </Grid>
                </>
              )} */}

              <Grid item xs={12} sm={6} md={2}>
                <Button
                  style={{ width: '100%', height: '50px', backgroundColor: '#001e4d' }}
                  variant="contained"
                  endIcon={<Search />}
                  onClick={handleFindClick}
                >
                  Find
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  style={{ width: '100%', height: '50px', backgroundColor: '#001e4d' }}
                  variant="contained"
                  endIcon={<Clear />}
                  onClick={handleClearClick}
                >
                  Clear
                </Button>
              </Grid>
            </>
          )}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
            </Grid>*/}

          {/* <Grid item xs={12} md={6} lg={4}> */}

          {isSuper && (
            <Grid item xs={12} sm={6} md={6}>
              <CustomBinTable type="sales" column_data={['SN', 'Company', 'Winnings', 'Total Cut', 'Total Trxs']} />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={6}>
            <AppCurrentVisits
              title="Sales Percentage By Branch For Current Month"
              chartData={get_chart_data()}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          {(user?.role === 'super' || user?.role === 'Agent' || user?.role === 'SubAgent') && (
            <>
            
            <Grid item xs={12} sm={6} md={6}>
            <h6>{attendance_is_loading ? 'Loading Attendance ...' : 'Attendance'}</h6>
              <CustomBinTable type="attendance" column_data={['SN', 'Company', 'Date', 'Cashier', 'Status']} />
            </Grid>
            </>
          )}

          {(user?.role === 'super' || user?.role === 'Agent' || user?.role === 'SubAgent') && (
            <>
            
            <Grid item xs={12} sm={6} md={6}>
            <h6>{company_active_status_loading ? 'Loading Company Status ...' : 'Company Status'}</h6>
              <CustomBinTable type="company_status" column_data={['SN', 'Company', 'Active', 'Status']} />
            </Grid>
            </>
          )}

          {/*<Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="n Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid>*/}
        </Grid>
      </Container>
    </>
  );
}
